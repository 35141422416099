// threadsClientApi.ts

import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../config/firebase";
import {
  LoadThreadsCredentialsRequest,
  LoadThreadsCredentialsResponse,
  ExchangeCodeForTokenRequest,
  ExchangeCodeForTokenResponse,
  GetThreadsRequest,
  GetThreadsResponse as ApiGetThreadsResponse,
  GetThreadsUserRequest,
  GetThreadsUserResponse,
  GetThreadsUserInsightsRequest,
  GetThreadsUserInsightsResponse,
  FetchThreadsReportHandlerRequest,
  FetchThreadsReportHandlerResponse,
  AnalyzeSingleThreadRequest,
  AnalyzeSingleThreadResponse,
  GenerateThreadsReportRequest,
  GenerateThreadsReportResponse,
} from "../../../functions/src/types/threadsApiTypes";

/**
 * SCOPES
 * If you need these for an authorization flow, just export them for use elsewhere.
 */
const THREADS_SCOPES = "threads_basic,threads_manage_insights";
export const getThreadsScopes = () => THREADS_SCOPES;

/**
 * Initialize Firebase Functions with a region fallback.
 */
const functions = getFunctions(app, process.env.REACT_APP_REGION || "us-west1");

/**
 * A generic helper that:
 * - Calls the specified Firebase function by name.
 * - Performs standard checks:
 *   1) Ensures it's not an HTML error page.
 *   2) Checks for an `error` field in the response.
 * - Returns the typed result if all is good, otherwise throws an error.
 */
async function callThreadsFunction<TReq, TRes>(
  functionName: string,
  data: TReq
): Promise<TRes> {
  const fn = httpsCallable<TReq, TRes>(functions, functionName);
  const result = await fn(data);

  // Check for a possible HTML error page
  if (typeof result.data === "string" && result.data.trim().startsWith("<")) {
    throw new Error(
      `Received HTML response from ${functionName} instead of JSON.`
    );
  }

  // Check if data indicates an error
  if (!result.data || "error" in (result.data as any)) {
    const errorMessage =
      ((result.data as any)?.error?.message as string) ||
      `Unknown error calling ${functionName}`;
    throw new Error(errorMessage);
  }

  return result.data;
}

/**
 * ====================
 * Threads-Related API Calls
 * ====================
 *
 * Each function calls the unified `callThreadsFunction` with the appropriate
 * request & response types, plus the name of the Firebase Function to invoke.
 */

/**
 * 1) loadThreadsCredentialsFunctions
 *    Fetches stored credentials for a user, or returns null if not found.
 */
export async function loadThreadsCredentialsFunctions(
  uid: string
): Promise<LoadThreadsCredentialsResponse | null> {
  // We accept that the function can return { data: undefined } if not found,
  // so we allow null in that case.
  const data = await callThreadsFunction<
    LoadThreadsCredentialsRequest["data"],
    LoadThreadsCredentialsResponse | null
  >("loadThreadsCredentials", { uid });
  // If the server returns { data: undefined } or null, we interpret that as no credentials.
  if (!data || !data.data) {
    return null;
  }
  return data;
}

/**
 * 2) exchangeCodeForTokenFunctions
 *    Exchanges a short-lived code for a long-lived token using the Threads API.
 */
export async function exchangeCodeForTokenFunctions(
  code: string,
  uid: string
): Promise<ExchangeCodeForTokenResponse> {
  return callThreadsFunction<
    ExchangeCodeForTokenRequest["data"],
    ExchangeCodeForTokenResponse
  >("exchangeCodeForToken", { code, uid });
}

/**
 * 3) getThreadsFunctions
 *    Fetches threads (cached or API) for a user. Returns an object with { threads: Thread[] }.
 */
export async function getThreadsFunctions(
  uid: string,
  forceRefresh = false,
  threadsUserId?: string
): Promise<ApiGetThreadsResponse> {
  return callThreadsFunction<GetThreadsRequest["data"], ApiGetThreadsResponse>(
    "getThreads",
    { uid, forceRefresh, threadsUserId }
  );
}

/**
 * 4) getThreadsUserFunctions
 *    Fetches a user’s Threads profile (cached or from API).
 */
export async function getThreadsUserFunctions(
  uid: string,
  forceRefresh = false,
  threadsUserId?: string
): Promise<GetThreadsUserResponse> {
  return callThreadsFunction<
    GetThreadsUserRequest["data"],
    GetThreadsUserResponse
  >("getThreadsUser", { uid, forceRefresh, threadsUserId });
}

/**
 * 5) getThreadsUserInsightsFunctions
 *    Retrieves user-level insights (cached or from API).
 */
export async function getThreadsUserInsightsFunctions(
  uid: string,
  forceRefresh = false,
  threadsUserId?: string
): Promise<GetThreadsUserInsightsResponse> {
  return callThreadsFunction<
    GetThreadsUserInsightsRequest["data"],
    GetThreadsUserInsightsResponse
  >("getThreadsUserInsights", { uid, forceRefresh, threadsUserId });
}

/**
 * 6) fetchThreadsReportFunctions
 *    Attempts to retrieve a cached LLM report. Returns null if an error occurs or no data is found.
 */
export async function fetchThreadsReportFunctions(
  uid: string,
  threadsUserId?: string
): Promise<FetchThreadsReportHandlerResponse | null> {
  try {
    // Some calls want to return null if there's no cached data.
    const result = await callThreadsFunction<
      FetchThreadsReportHandlerRequest["data"],
      FetchThreadsReportHandlerResponse | null
    >("fetchThreadsReport", { uid, threadsUserId });

    // If the server returns an empty or error response, we interpret it as no cached report.
    // callThreadsFunction already handles most error cases, so reaching here means we have a result.
    return result;
  } catch (err) {
    // We explicitly want to return null if the function fails or no data is found.
    console.error("Error fetching threads report:", err);
    return null;
  }
}

/**
 * 7) generateThreadsReportFunctions
 *    Triggers an LLM-based analysis of all threads. Uses cache if the server does so by default.
 */
export async function generateThreadsReportFunctions(
  uid: string,
  threadsUserId?: string
): Promise<GenerateThreadsReportResponse> {
  return callThreadsFunction<
    GenerateThreadsReportRequest["data"],
    GenerateThreadsReportResponse
  >("generateThreadsReport", { uid, threadsUserId });
}

/**
 * 8) analyzeSingleThreadFunctions
 *    Fetches and runs an LLM analysis on a single thread.
 */
export async function analyzeSingleThreadFunctions(
  uid: string,
  threadId: string,
  threadsUserId?: string
): Promise<AnalyzeSingleThreadResponse> {
  return callThreadsFunction<
    AnalyzeSingleThreadRequest["data"],
    AnalyzeSingleThreadResponse
  >("analyzeSingleThread", { uid, threadId, threadsUserId });
}
