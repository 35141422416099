import React from "react";
import {
  Box,
  Typography,
  Button,
  Chip,
  styled,
  CardContent,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { ko } from "date-fns/locale";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import { formatPromptUsingRegex } from "../../utils/stringFormat";
import { log } from "../../config/firebase";
import { Prompt } from "types";
import { copyToClipboard } from "../../utils/utils";

const StyledChip = styled(Chip)({
  margin: "4px",
  backgroundColor: "#333333",
  color: "#E0E0E0",
  "& .MuiChip-label": {
    fontSize: "0.75rem",
    fontFamily: "'Inter', sans-serif",
  },
  "&:hover": {
    backgroundColor: "#444444",
    transform: "scale(1.05)",
  },
  transition: "all 0.2s ease-in-out",
});

const StyledButton = styled(Button)({
  backgroundColor: "#2A2A2A",
  color: "#E0E0E0",
  borderColor: "#333333",
  padding: "8px 16px",
  "&:hover": {
    backgroundColor: "#333333",
    borderColor: "#444444",
  },
  transition: "all 0.2s ease-in-out",
});

interface PromptCardProps {
  prompt: Partial<Prompt>;
}

const PromptCard: React.FC<PromptCardProps> = ({ prompt }) => {
  return (
    <CardContent>
      <Box>
        {prompt.gpt_persona && (
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: "'Inter', sans-serif",
              color: "#999999",
              mb: 1,
            }}
          >
            {prompt.gpt_persona}의
          </Typography>
        )}
        <Typography
          variant="h6"
          fontWeight={600}
          sx={{
            fontFamily: "'Inter', sans-serif",
            letterSpacing: "0.02em",
            mb: 2,
          }}
        >
          {prompt.prompt_title}
        </Typography>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          {prompt.username ? (
            <Typography
              variant="subtitle2"
              fontWeight={600}
              sx={{
                fontFamily: "'Inter', sans-serif",
                color: "#999999",
              }}
            >
              작성자: {prompt.username}
            </Typography>
          ) : prompt.uid ? (
            <Typography
              variant="subtitle2"
              fontWeight={600}
              sx={{
                fontFamily: "'Inter', sans-serif",
                color: "#999999",
              }}
            >
              작성자: {prompt.uid.substring(0, 5)}***
            </Typography>
          ) : null}

          <Typography
            variant="caption"
            sx={{
              color: "#999999",
              fontFamily: "'Inter', sans-serif",
            }}
          >
            {prompt.updatedAt && !isNaN(new Date(prompt.updatedAt).getTime())
              ? formatDistanceToNow(new Date(prompt.updatedAt), {
                  addSuffix: true,
                  locale: ko,
                })
              : formatDistanceToNow(new Date(), {
                  addSuffix: true,
                  locale: ko,
                })}
          </Typography>
        </Box>

        <Box mb={3}>
          {prompt.prompt_tags?.map((tag, tagIndex) => (
            <StyledChip
              key={tagIndex}
              label={tag}
              sx={{
                backgroundColor: "#333333",
                color: "#E0E0E0",
              }}
            />
          ))}
        </Box>

        <Box
          sx={{
            backgroundColor: "#252525",
            padding: "16px",
            borderRadius: "8px",
            mb: 3,
            "& pre": {
              backgroundColor: "#2A2A2A",
              padding: "1rem",
              borderRadius: "4px",
              overflowX: "auto",
            },
            "& code": {
              backgroundColor: "#2A2A2A",
              padding: "0.2rem 0.4rem",
              borderRadius: "4px",
              fontSize: "0.875em",
              color: "#E0E0E0",
            },
          }}
        >
          <Typography
            component="div"
            variant="body2"
            sx={{
              color: "#E0E0E0",
              fontFamily: "'Inter', sans-serif",
              lineHeight: 1.6,
              letterSpacing: "0.01em",
            }}
          >
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              remarkPlugins={[remarkGfm]}
            >
              {formatPromptUsingRegex(prompt.prompt ?? "")}
            </ReactMarkdown>
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center">
          <StyledButton
            variant="outlined"
            onClick={() => {
              if (prompt.prompt) {
                log("prompt_explorer.copy_prompt_button_click", {
                  prompt: prompt.prompt_title,
                });
                copyToClipboard(formatPromptUsingRegex(prompt.prompt ?? ""));
              }
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontFamily: "'Inter', sans-serif",
                fontWeight: 500,
              }}
            >
              프롬프트 복사하기
            </Typography>
          </StyledButton>
        </Box>
      </Box>
    </CardContent>
  );
};

export default PromptCard;
