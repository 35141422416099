import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ROUTES from "./utils/routes";
import MainPage from "./pages/MainPage";
import Box from "@mui/material/Box";
import { CssBaseline, Typography } from "@mui/material";
import PromptGeneratorPage from "./pages/PromptGeneratorPage";
import mytheme from "./utils/theme";
import AuthProvider from "./provider/AuthProvider";
import ProfilePage from "./pages/ProfilePage";
import PromptExplorerPage from "./pages/PromptExplorerPage";
import PromptsPage from "./pages/PromptsPage";
import AdminPage from "./pages/AdminPage";
import PromptLayout from "./components/promptExplorer/PromptLayout";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import ThreadsPage from "./pages/ThreadsPage";
import { ThreadsProvider } from "./context/ThreadsContext";
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { analytics } from "./config/firebase";

const theme = createTheme(mytheme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Box component="main">
            <Box display="flex" flexDirection="column">
              <Routes>
                {/* main */}
                <Route path={ROUTES.main.path} element={<MainPage />} />
                {/* prompt_generator */}
                <Route
                  path={ROUTES.prompt_generator.path}
                  element={<PromptLayout />}
                >
                  <Route index element={<PromptGeneratorPage />} />
                  <Route
                    path={"*"}
                    element={
                      <Box p={3}>
                        <Typography variant="h3">
                          페이지를 찾을 수 없습니다
                        </Typography>
                      </Box>
                    }
                  />
                </Route>

                {/* prompts_explorer */}
                <Route
                  path={ROUTES.prompt_explorer.path}
                  element={<PromptLayout />}
                >
                  <Route index element={<PromptExplorerPage />} />
                  <Route
                    path={"*"}
                    element={
                      <Box p={3}>
                        <Typography variant="h3">
                          페이지를 찾을 수 없습니다
                        </Typography>
                      </Box>
                    }
                  />
                </Route>

                {/* profile */}
                <Route path={ROUTES.profile.path} element={<Outlet />}>
                  <Route index element={<ProfilePage />} />
                  <Route
                    path={"*"}
                    element={
                      <Box p={3}>
                        <Typography variant="h3">
                          페이지를 찾을 수 없습니다
                        </Typography>
                      </Box>
                    }
                  />
                </Route>

                {/* admin */}
                <Route path={ROUTES.admin.path} element={<Outlet />}>
                  <Route index element={<AdminPage />} />
                  <Route
                    path={"*"}
                    element={
                      <Box p={3}>
                        <Typography variant="h3">
                          페이지를 찾을 수 없습니다
                        </Typography>
                      </Box>
                    }
                  />
                </Route>

                {/* privacy policy */}
                <Route
                  path={ROUTES.privacy.path}
                  element={<PrivacyPolicyPage />}
                />

                {/* terms and conditions */}
                <Route
                  path={ROUTES.terms.path}
                  element={<TermsAndConditionsPage />}
                />

                {/* prompts */}
                <Route
                  path={ROUTES.prompts.path}
                  element={<PromptLayout />}
                >
                  <Route index element={<PromptsPage />} />
                  <Route
                    path={"*"}
                    element={
                      <Box p={3}>
                        <Typography variant="h3">
                          페이지를 찾을 수 없습니다
                        </Typography>
                      </Box>
                    }
                  />
                </Route>

                {/* threads */}
                <Route
                  path={ROUTES.threads.path}
                  element={
                    <ThreadsProvider>
                      <ThreadsPage />
                    </ThreadsProvider>
                  }
                />

                {/* 404 */}
                <Route
                  path={"*"}
                  element={
                    <Box p={3}>
                      <Typography variant="h3">
                        페이지를 찾을 수 없습니다
                      </Typography>
                    </Box>
                  }
                />
              </Routes>
            </Box>
          </Box>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
