import React from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  styled,
  CardProps,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ThreadsReport } from "../../../../functions/src/types/threadsTypes";

interface ThreadsReportCardProps extends CardProps {
  threadsReport: ThreadsReport;
}

const StyledCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  borderRadius: "12px",
  backgroundColor: "#1E1E1E",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
  transition: "all 0.2s ease-in-out",
});

const StyledBox = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "10px",
});

const ThreadsReportCard: React.FC<ThreadsReportCardProps> = ({
  threadsReport,
  ...cardProps
}) => {
  // We'll display the analysis text. Possibly more fields in the future.
  return (
    <StyledCard {...cardProps}>
      <CardContent sx={{ padding: "24px" }}>
        <Box mb={2} display="flex" justifyContent="center">
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            쓰레드 분석 결과
          </Typography>
        </Box>
        <StyledBox>
          <Box maxWidth="1200px">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {threadsReport.analysis}
            </ReactMarkdown>
          </Box>
        </StyledBox>
      </CardContent>
    </StyledCard>
  );
};

export default ThreadsReportCard;
