import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  styled,
  Box,
  Button,
  Chip,
  CardProps,
  Avatar,
  CircularProgress,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ThreadInsights } from "../../../../functions/src/types/threadsTypes";

function truncateText(text: string, maxLength: number) {
  if (!text) return "";
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + "...";
}

// change to korean
function formatExactDateTime(anyTs: unknown) {
  if (!anyTs) return "날짜 없음";
  if (typeof (anyTs as { toDate?: () => Date }).toDate === "function") {
    anyTs = (anyTs as { toDate: () => Date }).toDate();
  } else if (
    typeof anyTs === "object" &&
    anyTs !== null &&
    "_seconds" in anyTs &&
    "_nanoseconds" in anyTs
  ) {
    const { _seconds, _nanoseconds } = anyTs as {
      _seconds: number;
      _nanoseconds: number;
    };
    anyTs = new Date(_seconds * 1000 + _nanoseconds / 1000000);
  }
  const parsed = new Date(anyTs as string | number | Date);
  if (isNaN(parsed.getTime())) {
    return "날짜 없음";
  }
  return parsed.toLocaleString("ko-KR", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
}

const StyledChip = styled(Chip)({
  margin: "4px",
  backgroundColor: "#333333",
  color: "#E0E0E0",
  "& .MuiChip-label": {
    fontSize: "0.75rem",
  },
  "&:hover": {
    backgroundColor: "#444444",
    transform: "scale(1.05)",
  },
  transition: "all 0.2s ease-in-out",
});

const StyledCard = styled(Card)({
  minWidth: 300,
  maxWidth: 250,
  maxHeight: 430,
  margin: "8px",
  borderRadius: "12px",
  backgroundColor: "#1E1E1E",
  color: "#E0E0E0",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.4)",
    backgroundColor: "#252525",
  },
  "& .MuiTypography-root": {
    color: "#E0E0E0",
  },
  "& .MuiTypography-caption": {
    color: "#999999",
  },
  "& .MuiButton-root": {
    color: "#999999",
    "&:hover": {
      color: "#E0E0E0",
      backgroundColor: "rgba(255, 255, 255, 0.05)",
    },
  },
  "& .MuiCardContent-root": {
    padding: "16px",
  },
});

interface RichContentCardProps extends CardProps {
  onAnalyze?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  showAnalyzeButton?: boolean;
  title?: string;
  persona?: string;
  hideMainText?: boolean;
  updatedAt?: Date | string | null;
  mainText?: string;
  tags?: string[];
  likes?: number;
  comments?: number;
  reposts?: number;
  shares?: number;
  profilePicUrl?: string;
  onLike?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClickCard?: () => void;
  onClickTag?: (tag: string) => void;
  showLikeButton?: boolean;
  showCommentsCount?: boolean;
  showRepostsCount?: boolean;
  showSharesCount?: boolean;
  isLikeLoading?: boolean;
  noTruncate?: boolean;
  commentsCount?: number;
  loading?: boolean; // Added loading prop
  category?: string;
  expectedEngagement?: ThreadInsights;
}

const RichContentCard: React.FC<RichContentCardProps> = ({
  onAnalyze,
  showAnalyzeButton,
  title,
  persona,
  updatedAt,
  category,
  expectedEngagement,
  mainText,
  tags,
  likes = 0,
  comments = 0,
  reposts = 0,
  shares = 0,
  profilePicUrl,
  onLike,
  onClickCard,
  onClickTag,
  showLikeButton = false,
  showCommentsCount = false,
  showRepostsCount = false,
  showSharesCount = false,
  isLikeLoading = false,
  hideMainText = false,
  noTruncate = false,
  commentsCount,
  loading = false,
  ...cardProps
}) => {
  const [showMainText, setShowMainText] = useState(false);

  if (!title || !mainText) return null;
  return (
    <StyledCard
      {...cardProps}
      onClick={loading ? undefined : onClickCard}
      sx={{
        cursor: loading ? "default" : onClickCard ? "pointer" : "default",
        opacity: loading ? 0.7 : 1,
        position: "relative",
      }}
    >
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: 1,
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
      <CardContent>
        <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
          {profilePicUrl && (
            <Avatar
              src={profilePicUrl}
              alt={persona ?? "profile"}
              sx={{ width: 32, height: 32, mr: 1 }}
            />
          )}
          <Box>
            {persona && (
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  fontFamily: "'Inter', sans-serif",
                  letterSpacing: "0.02em",
                }}
              >
                {persona}
              </Typography>
            )}
            {updatedAt && (
              <Typography
                variant="caption"
                sx={{
                  color: "#999999",
                  fontFamily: "'Inter', sans-serif",
                }}
              >
                {formatExactDateTime(updatedAt)}
              </Typography>
            )}
          </Box>
        </Box>

        <Box>
          {title && (
            <Typography
              variant="subtitle1"
              fontWeight={600}
              sx={{
                fontFamily: "'Inter', sans-serif",
                letterSpacing: "0.01em",
                m: 1,
              }}
            >
              {title}
            </Typography>
          )}

          {category && (
            <Box mb={1}>
              <StyledChip
                label={"카테고리: " + category}
                sx={{
                  backgroundColor: "#00695C",
                  color: "#FFFFFF",
                  mr: 1,
                }}
              />
            </Box>
          )}

          {mainText &&
            (hideMainText && !showMainText ? null : (
              <Box
                sx={{
                  mt: 1,
                  mb: 2,
                  height: 100,
                  overflow: "auto",
                  "& img": { maxWidth: "100%" },
                  "& pre": {
                    backgroundColor: "#2A2A2A",
                    padding: "1rem",
                    borderRadius: "4px",
                    overflowX: "auto",
                  },
                  "& code": {
                    backgroundColor: "#2A2A2A",
                    padding: "0.2rem 0.4rem",
                    borderRadius: "4px",
                    fontSize: "0.875em",
                    color: "#E0E0E0",
                  },
                  "& table": {
                    borderCollapse: "collapse",
                    width: "100%",
                    marginBottom: "1rem",
                  },
                  "& th, & td": {
                    border: "1px solid #333333",
                    padding: "0.5rem",
                  },
                  "& blockquote": {
                    borderLeft: "4px solid #333333",
                    margin: "0",
                    paddingLeft: "1rem",
                    color: "#999999",
                  },
                }}
              >
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    p: ({ children }) => (
                      <Typography
                        variant="body2"
                        component="div"
                        sx={{
                          color: "#E0E0E0",
                          lineHeight: 1.6,
                          letterSpacing: "0.01em",
                          fontFamily: "'Inter', sans-serif",
                        }}
                      >
                        {children}
                      </Typography>
                    ),
                  }}
                >
                  {noTruncate ? mainText : truncateText(mainText, 150)}
                </ReactMarkdown>
              </Box>
            ))}
        </Box>

        {tags && tags.length > 0 && (
          <Box mb={2}>
            {tags.map((tag, idx) => (
              <StyledChip
                key={idx}
                label={tag}
                onClick={() => onClickTag && onClickTag(tag)}
                sx={{
                  backgroundColor: "#333333",
                  color: "#E0E0E0",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    backgroundColor: "#444444",
                  },
                }}
              />
            ))}
          </Box>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderTop: "1px solid #333333",
            pt: 2,
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
            {showLikeButton ? (
              <Button
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onLike && onLike(e);
                }}
                disabled={!!isLikeLoading || loading}
                sx={{
                  color: "#999999",
                  "&:hover": {
                    color: "#E0E0E0",
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                  },
                }}
              >
                <Typography variant="caption">{likes} 좋아요</Typography>
              </Button>
            ) : (
              <Typography variant="caption" sx={{ color: "#999999" }}>
                {likes} 좋아요
              </Typography>
            )}

            {showCommentsCount && (
              <Typography variant="caption" sx={{ color: "#999999" }}>
                {commentsCount || comments || 0} 답글
              </Typography>
            )}

            {showRepostsCount && (
              <Typography variant="caption" sx={{ color: "#999999" }}>
                {reposts} 리포스트
              </Typography>
            )}

            {showSharesCount && (
              <Typography variant="caption" sx={{ color: "#999999" }}>
                {shares} 공유
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
          {showAnalyzeButton && !tags && (
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onAnalyze && onAnalyze(e);
              }}
              variant="contained"
              disabled={loading}
              sx={{
                color: "#999999",
                "&:hover": {
                  color: "#E0E0E0",
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                },
              }}
            >
              <Typography variant="caption">쓰레드 분석하기</Typography>
            </Button>
          )}
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default RichContentCard;
