import {
  Box,
  Button,
  Divider,
  Typography,
  styled,
  CircularProgress,
} from "@mui/material";
import TopBar from "../navigations/TopBar";
import PersonaCards from "../components/promptGenerator/PersonaCards";
import { useContext, useEffect, useState } from "react";
import GoalCards from "../components/promptGenerator/GoalCards";
import TaskCards from "../components/promptGenerator/TaskCards";
import { generatePrompt } from "../api/promptApi";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { UserInputType } from "../../../functions/src/types/promptsTypes";
import { PersonasContext } from "../context/PersonasContext";
import { formatPromptUsingRegex } from "../utils/stringFormat";
import { useAuth } from "../context/AuthContext";
import { log } from "../config/firebase";
import { copyToClipboard } from "../utils/utils";
import SignInModal from "../components/profile/SignInModal";

const StyledContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  textAlign: "center",
  flexGrow: 1,
  width: "100%",
  maxWidth: "100%",
  backgroundColor: "#121212",
  minHeight: "100vh",
  color: "#E0E0E0",
});

const StyledButton = styled(Button)({
  borderRadius: "12px",
  padding: "16px 32px",
  backgroundColor: "#1E1E1E",
  color: "#E0E0E0",
  border: "none",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#252525",
    transform: "scale(1.02)",
  },
  "&.Mui-disabled": {
    backgroundColor: "#1A1A1A",
    color: "#666666",
  },
});

const PromptBox = styled(Box)({
  margin: "24px",
  padding: "24px",
  maxWidth: "600px",
  textAlign: "justify",
  border: "1px solid #333333",
  borderRadius: "12px",
  backgroundColor: "#1E1E1E",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
  "& .MuiTypography-root": {
    color: "#E0E0E0",
  },
  "& pre": {
    backgroundColor: "#252525",
    padding: "16px",
    borderRadius: "8px",
    overflowX: "auto",
  },
  "& code": {
    backgroundColor: "#252525",
    padding: "4px 8px",
    borderRadius: "4px",
    color: "#E0E0E0",
  },
});

const PromptGeneratorPage: React.FC = () => {
  const { user, isAuthenticated } = useAuth();
  const { personas, goals } = useContext(PersonasContext);
  const [selectedPersonaID, setSelectedPersonaID] = useState("");
  const [selectedGoalID, setSelectedGoalID] = useState("");
  const [selectedTask, setSelectedTask] = useState("");
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [isGenerateTriggered, setGenerateTriggered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [error, setError] = useState("");

  const canGeneratePrompt =
    selectedPersonaID !== "" && selectedGoalID !== "" && selectedTask !== "";

  useEffect(() => {
    log("prompt_generator.page_view");
  }, []);

  useEffect(() => {
    if (!selectedPersonaID || !selectedGoalID || !selectedTask) {
      setGenerateTriggered(false);
      setPrompt("");
      setIsLoading(false);
    }
    async function fetchData() {
      if (!selectedPersonaID || !selectedGoalID || !selectedTask) return;
      setIsLoading(true);
      try {
        const selectedPersona = personas.find(
          (persona) => persona.id === selectedPersonaID
        );
        const selectedGoal = goals.find((goal) => goal.id === selectedGoalID);
        if (selectedPersona === null || selectedPersona === undefined) {
          throw Error("selected persona is empty");
        }
        if (selectedGoal === null || selectedGoal === undefined) {
          throw Error("selected goal is empty");
        }
        const data: UserInputType = {
          uid: user?.uid ?? "",
          persona: selectedPersona,
          goal: selectedGoal,
          task: selectedTask,
        };
        await generatePrompt(JSON.stringify(data), setPrompt, setIsLoading);
      } catch (error) {
        console.error(error);
        setError(
          "서버 오류가 생겼습니다. 다시 한 번 시도해주시거나, 디스코드 채널에 버그를 리포트해주세요!"
        );
        setIsLoading(false);
      }
    }

    if (isGenerateTriggered) {
      fetchData();
      setGenerateTriggered(false);
    }
  }, [
    isGenerateTriggered,
    selectedPersonaID,
    selectedGoalID,
    selectedTask,
    personas,
    user?.uid,
    goals,
  ]);

  const handleButtonClick = () => {
    if (!user || !isAuthenticated) {
      log("prompt_generator.generate_prompt_button_click_sign_in", {
        persona: selectedPersonaID,
        goal: selectedGoalID,
        task: selectedTask,
      });
      setOpenSignInModal(true);
      return;
    }
    if (canGeneratePrompt) {
      log("prompt_generator.generate_prompt_button_click", {
        persona: selectedPersonaID,
        goal: selectedGoalID,
        task: selectedTask,
      });
      setGenerateTriggered(true);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#121212",
        minHeight: "100vh",
        color: "#E0E0E0",
      }}
    >
      <TopBar />
      <StyledContainer>
        <Box mt={4} mb={4}>
          <Typography
            variant="h4"
            fontWeight={600}
            sx={{
              fontFamily: "'Inter', sans-serif",
              letterSpacing: "0.02em",
            }}
          >
            프롬프트 만들기
          </Typography>
        </Box>

        <PersonaCards
          selectedPersonaID={selectedPersonaID}
          setSelectedPersonaID={setSelectedPersonaID}
          setSelectedGoalID={setSelectedGoalID}
        />

        <Divider
          sx={{
            display: selectedPersonaID ? "block" : "none",
            width: "95%",
            borderColor: "#333333",
            my: 3,
          }}
        />

        <GoalCards
          selectedPersonaID={selectedPersonaID}
          selectedGoalID={selectedGoalID}
          setSelectedGoalID={setSelectedGoalID}
        />

        <Divider
          sx={{
            display: selectedGoalID ? "block" : "none",
            width: "95%",
            borderColor: "#333333",
            my: 3,
          }}
        />

        <TaskCards
          selectedGoalID={selectedGoalID}
          selectedTask={selectedTask}
          setSelectedTask={setSelectedTask}
        />

        {error && (
          <Box display="flex" flexDirection="column" sx={{ mt: 3 }}>
            <Typography sx={{ color: "#ff6b6b" }}>{error}</Typography>
            <Box m={2}>
              <a
                href="https://discord.gg/Vr7VGn9SZR"
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledButton sx={{ m: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontFamily: "'Inter', sans-serif" }}
                  >
                    버그 리포트하기
                  </Typography>
                </StyledButton>
              </a>
              <StyledButton sx={{ m: 1 }} onClick={() => setError("")}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontFamily: "'Inter', sans-serif" }}
                >
                  다시 시도해보기
                </Typography>
              </StyledButton>
            </Box>
          </Box>
        )}

        {canGeneratePrompt && prompt === "" && (
          <Box m={3}>
            <StyledButton
              disabled={isLoading || prompt !== "" || !canGeneratePrompt}
              onClick={handleButtonClick}
            >
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ fontFamily: "'Inter', sans-serif" }}
              >
                {isLoading ? (
                  <>
                    프롬프트 생성중입니다...
                    <br />
                    <CircularProgress
                      sx={{ mt: 1 }}
                      color="inherit"
                      size={24}
                    />
                  </>
                ) : (
                  "나만의 프롬프트 만들기"
                )}
              </Typography>
            </StyledButton>
          </Box>
        )}

        {prompt !== "" && (
          <PromptBox>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  pb={2}
                  fontWeight={600}
                  sx={{
                    fontFamily: "'Inter', sans-serif",
                    letterSpacing: "0.02em",
                  }}
                >
                  프롬프트
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#252525",
                  padding: "16px",
                  borderRadius: "8px",
                  "& p": {
                    color: "#E0E0E0",
                    fontFamily: "'Inter', sans-serif",
                    lineHeight: 1.6,
                    letterSpacing: "0.01em",
                  },
                }}
              >
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  remarkPlugins={[remarkGfm]}
                >
                  {formatPromptUsingRegex(prompt)}
                </ReactMarkdown>
              </Box>
            </Box>

            {!isLoading && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 3,
                }}
              >
                <StyledButton
                  sx={{ m: 1 }}
                  onClick={() => {
                    log("prompt_generator.copy_prompt_button_click", {
                      prompt,
                    });
                    copyToClipboard(formatPromptUsingRegex(prompt));
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ fontFamily: "'Inter', sans-serif" }}
                  >
                    프롬프트 복사하기
                  </Typography>
                </StyledButton>
                <StyledButton
                  sx={{ m: 1 }}
                  onClick={() => {
                    setSelectedTask("");
                    setPrompt("");
                    log("prompt_generator.create_new_prompt_button_click");
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ fontFamily: "'Inter', sans-serif" }}
                  >
                    새로운 프롬프트 만들기
                  </Typography>
                </StyledButton>
              </Box>
            )}
          </PromptBox>
        )}

        <SignInModal open={openSignInModal} setOpen={setOpenSignInModal} />
      </StyledContainer>
    </Box>
  );
};

export default PromptGeneratorPage;
