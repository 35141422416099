// PrivacyPolicyPage.tsx
// -----------------------------------
// Summary of Updates:
// 1) Comprehensive coverage of data collection (including Threads API, Firebase, OpenAI) and usage.
// 2) Detailed user rights section, including CCPA (California) and international considerations (South Korean PIPA).
// 3) Clear disclaimers on data retention, security, user rights (access, deletion, opt-out).
// 4) Explanation of third-party integrations and how data is handled.
// 5) Updated contact info for Ipsum AI LLC and references to dedicated emails for privacy/legal questions.

import React, { useEffect } from "react";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import { Box } from "@mui/material";
import { log } from "../config/firebase";

const privacyPolicy = `# Privacy Policy

_Last Updated: [Month Day, Year]_

Ipsum AI LLC (“**we**” or “**us**”), as the operator of **Career Hacker Alex** (“**the Service**”), is committed to protecting your privacy. This Privacy Policy explains how we collect, use, share, and protect your personal information when you use Career Hacker Alex. We follow applicable U.S. laws and regulations, including the California Consumer Privacy Act (CCPA), to safeguard your data. If you are located in other jurisdictions, such as South Korea, we also address relevant international data protection considerations. **By using the Service, you agree to the data practices described in this Policy.** If you do not agree, please do not use Career Hacker Alex.

## 1. Data Collection

**Information You Provide**  
- **Account Information:** When you register, we may collect your name, email, password, and other details you choose to provide.  
- **Content & Communications:** We collect queries or prompts you submit to the AI, feedback you provide, and any content you post in community forums.  

**Information Collected Automatically**  
- **Usage Data:** e.g., pages visited, features used, timestamps.  
- **Device & Technical Data:** IP address, browser details, device identifiers, cookies.  

**Information from Third Parties**  
- **Threads API (Meta):** If you connect your Threads account, we may receive user IDs and posts from Threads to enable features in our Service.  
- **Social Login:** If you log in via Google or other social platforms, we may receive basic profile info from those services.  

No user under 13 is permitted to provide data; we do not knowingly collect from children under 13. If you believe a child has used our Service, please contact us to remove that information.

## 2. Third-Party Services

We rely on third-party providers, such as:  
- **OpenAI:** Processes AI queries. We only share the content of your queries, not your personally identifiable info, unless included within your query.  
- **Firebase (Google):** For authentication, hosting, and analytics. Some of your data may be stored on Google's servers.  
- **Threads API:** If you link or use Threads features, interactions with Meta's Threads service apply.  
Please refer to these third parties' privacy policies for details on their practices.

## 3. Data Security & Retention

We implement safeguards (encryption, secure hosting, access controls) to protect your data. However, no electronic system is 100% secure. We retain personal information as long as necessary for the purpose collected or as required by law. When no longer needed, we securely dispose of your information.

## 4. User Rights

### CCPA (California Residents)

- **Right to Know/Access:** You may request the categories and specific pieces of personal info we've collected.  
- **Right to Delete:** You may request deletion of your info, subject to legal exceptions.  
- **Right to Opt Out of Sale/Sharing:** We do not sell your personal data.  
- **Right to Correct:** You may ask to correct inaccurate info.  
- **Right to Non-Discrimination:** We won't penalize you for exercising your privacy rights.

### South Korean Users

By using our U.S.-based Service, you consent to transfer of your data. We comply with PIPA to protect and securely process your data, and honor access/correction/deletion requests as provided by law.

## 5. International Considerations

If you're outside the U.S., your data may be transferred to and stored in the U.S. or other locations with different data protection laws. We take steps to ensure appropriate safeguards for cross-border transfers.

## 6. Policy Updates

We may update this Policy periodically. The “Last Updated” date reflects the most recent changes. If changes are material, we'll provide additional notice (e.g., via email or in-Service alert). Continued use after updates means you accept the revised policy.

## 7. Contact Information

**Ipsum AI LLC**
Email: [careerhackeralex@gmail.com](careerhackeralex@gmail.com)

If you have any questions or requests (including data deletion), please contact us.  
`;

const PrivacyPolicyPage: React.FC = () => {
  useEffect(() => {
    log("privacy_policy.page_view");
  }, []);

  return (
    <Box m={5}>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
        {privacyPolicy}
      </ReactMarkdown>
    </Box>
  );
};

export default PrivacyPolicyPage;
