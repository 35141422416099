import React, { useEffect, useState } from "react";
import {
  Home,
  Menu,
  CreateOutlined,
  SearchOutlined,
  AccountCircleOutlined,
  Logout,
  ChatOutlined,
} from "@mui/icons-material";
import {
  Box,
  Drawer,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import ROUTES from "../utils/routes"; // Import your routes
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { log } from "../config/firebase";

const DrawerNavigationBar: React.FC = () => {
  const { user, userInfo, isAuthenticated, logout } = useAuth();
  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setOpenDrawer(false);
  }, [location]);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = (destination: string) => {
    setOpenDrawer(false);
  };

  const handleLogout = async () => {
    if (user && isAuthenticated()) {
      try {
        await logout();
        log("logout");
      } catch (error) {
        console.error("Error logging out:", error);
      }
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
      {" "}
      {/* Ensure alignment and no unnecessary spacing */}
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2 }}
      >
        <Menu color="inherit" />
      </IconButton>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => handleDrawerClose("Close")}
      >
        <Box sx={{ width: 300 }}>
          <Typography variant="h6" color="primary" sx={{ m: 2, ml: 2 }}>
            {userInfo?.username ?? "커리어해커"}님, 안녕하세요!
          </Typography>
          <Divider />
          <List>
            {/* Home */}
            <ListItem sx={{ p: 2 }} color="primary" key={"main"}>
              <Link
                to={ROUTES.main.path}
                onClick={() => handleDrawerClose("Home")}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ListItemIcon>
                  <Home color="primary" />
                </ListItemIcon>
                <Typography color="primary"> 메인화면 </Typography>
              </Link>
            </ListItem>
            {/* Prompt Generator */}
            <ListItem sx={{ p: 2 }} color="primary" key={"prompt_generator"}>
              <Link
                to={ROUTES.prompt_generator.path}
                onClick={() => handleDrawerClose("Prompt Generator")}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ListItemIcon>
                  <CreateOutlined color="primary" />
                </ListItemIcon>
                <Typography color="primary">내 프롬프트 만들기</Typography>
              </Link>
            </ListItem>
            {/* Prompt Explorer */}
            <ListItem sx={{ p: 2 }} color="primary" key={"prompt_explorer"}>
              <Link
                to={ROUTES.prompt_explorer.path}
                onClick={() => handleDrawerClose("Prompt Explorer")}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ListItemIcon>
                  <SearchOutlined color="primary" />
                </ListItemIcon>
                <Typography color="primary">원하는 프롬프트 찾기</Typography>
              </Link>
            </ListItem>
            {/* ChatGPT Prompts */}
            <ListItem sx={{ p: 2 }} color="primary" key={"prompts"}>
              <Link
                to={ROUTES.prompts.path}
                onClick={() => handleDrawerClose("ChatGPT Prompts")}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ListItemIcon>
                  <ChatOutlined color="primary" />
                </ListItemIcon>
                <Typography color="primary">ChatGPT 프롬프트</Typography>
              </Link>
            </ListItem>
            {/* Profile */}
            {user && isAuthenticated() && (
              <ListItem sx={{ p: 2 }} color="primary" key={"profile"}>
                <Link
                  to={ROUTES.profile.path}
                  onClick={() => handleDrawerClose("Profile")}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <ListItemIcon>
                    <AccountCircleOutlined color="primary" />
                  </ListItemIcon>
                  <Typography color="primary"> 내 프로필 보기 </Typography>
                </Link>
              </ListItem>
            )}
            {/* Logout */}
            {user && isAuthenticated() && (
              <ListItem sx={{ p: 2 }} color="primary" key={"logout"}>
                <Box
                  sx={{
                    cursor: "pointer",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={handleLogout}
                >
                  <ListItemIcon>
                    <Logout color="primary" />
                  </ListItemIcon>
                  <Typography color="primary"> 로그아웃 </Typography>
                </Box>
              </ListItem>
            )}
            {/* Sign In - Only show when user is not authenticated */}
            {(!user || !isAuthenticated()) && (
              <ListItem sx={{ p: 2 }} color="primary" key={"signin"}>
                <Link
                  to={ROUTES.profile.path}
                  onClick={() => handleDrawerClose("SignIn")}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <ListItemIcon>
                    <AccountCircleOutlined color="primary" />
                  </ListItemIcon>
                  <Typography color="primary"> 로그인 </Typography>
                </Link>
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default DrawerNavigationBar;
