import React, { useEffect, useState } from "react";
import { useThreads } from "../context/ThreadsContext";
import { useAuth } from "../context/AuthContext";
// import { loadThreadsCredentialsFunctions } from "../api/threadsApi"; // Removed if context already loads
import TopBar from "../navigations/TopBar";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert,
  styled,
  Select,
  MenuItem,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import ThreadsMetricsCard from "../components/threads/ThreadsMetricsCard";
import ThreadsUserCard from "../components/threads/ThreadsUserCard";
import ThreadsReportCard from "../components/threads/ThreadsReportCard";
import ThreadsContainer from "../components/threads/ThreadsContainer";
import { sortThreadsByType } from "../utils/threadsUtils";
import { Thread } from "../../../functions/src/types/threadsTypes";

const StyledContainer = styled("div")({
  color: "#E0E0E0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  "& .MuiTypography-root": {
    color: "#E0E0E0",
  },
  "& .MuiButton-contained": {
    backgroundColor: "#1E1E1E",
    color: "#E0E0E0",
    "&:hover": {
      backgroundColor: "#333333",
    },
    transition: "background-color 0.2s ease-in-out",
  },
});

const StyledInnerContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "80%",
});

const HeaderContainer = styled("div")({
  color: "#E0E0E0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "20px",
  width: "100%",
});

export type ThreadsSortTypes =
  | "date"
  | "likes"
  | "replies"
  | "reposts"
  | "shares"
  | "views";

const ThreadsPage: React.FC = () => {
  const [authLoading, setAuthLoading] = useState(true);
  const { user, signInWithGoogle } = useAuth();
  const {
    threads,
    threadsUser,
    threadsUserId,
    threadsUserInsights,
    threadsReport,
    threadsMetrics,
    loadingAuthAndCredentials,
    loadingThreads,
    loadingThreadsUser,
    loadingForThreadsReport,
    error,
    isAuthorized,
    reauthNeeded,
    manualAuthorize,
    generateThreadsReport,
    handleAuthorizationCode,
  } = useThreads();

  const location = useLocation();
  const [authCodeLoading, setAuthCodeLoading] = useState(false);
  const [isAuthCodeHandled, setIsAuthCodeHandled] = useState(false);

  // Sorting state
  const [sortedThreads, setSortedThreads] = useState<Thread[]>([]);
  const [threadsSortType, setThreadsSortType] =
    useState<ThreadsSortTypes>("date");

  // 1) Once user changes, we mark auth loading false
  useEffect(() => {
    setAuthLoading(false);
  }, [user]);

  // 2) Re-sort threads whenever threads or threadsSortType changes
  useEffect(() => {
    setSortedThreads(Array.from(sortThreadsByType(threads, threadsSortType)));
  }, [threads, threadsSortType]);

  // 3) Check if there's an OAuth code in URL
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");
    // If code is present, user is logged in, and we haven't handled the code yet:
    if (code && user && !isAuthCodeHandled) {
      console.log("[ThreadsPage] Found 'code' param in URL:", code);
      setAuthCodeLoading(true);
      handleAuthorizationCode(code).finally(() => {
        setAuthCodeLoading(false);
        setIsAuthCodeHandled(true);

        // Remove 'code' param from URL
        urlParams.delete("code");
        const newSearch = urlParams.toString();
        const newUrl = `${window.location.pathname}${
          newSearch ? `?${newSearch}` : ""
        }`;
        window.history.replaceState({}, "", newUrl);
      });
    }
  }, [location, user, isAuthCodeHandled, handleAuthorizationCode]);

  // [Optional] 4) Removed: explicit loadThreadsCredentialsFunctions call
  // Because ThreadsContext already loads credentials if user is present

  // 6) Trigger an LLM-based threads report
  const handleGenerateThreadsReport = async () => {
    console.log("[ThreadsPage] handleGenerateThreadsReport triggered");
    if (!isAuthorized || !threadsUserId) {
      console.log("[ThreadsPage] Not authorized; please authorize first");
      return;
    }
    try {
      await generateThreadsReport(threadsUserId);
      console.log("[ThreadsPage] handleGenerateThreadsReport => completed");
    } catch (err) {
      console.error("[ThreadsPage] handleGenerateThreadsReport failed:", err);
    }
  };

  /**
   * ================
   * RENDER LOGIC
   * ================
   */
  // A) If we’re still loading auth or waiting on the context to load credentials:
  if (authLoading || authCodeLoading || loadingAuthAndCredentials) {
    return (
      <StyledContainer>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress size={40} color="primary" />
        </Box>
      </StyledContainer>
    );
  }

  // B) If no user is signed in, show sign in prompt
  if (!user) {
    return (
      <StyledContainer>
        <Box
          py={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}
        >
          {authCodeLoading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <Typography variant="h5">
                쓰레드를 보고 분석하려면 로그인하세요
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={signInWithGoogle}
              >
                구글로 로그인
              </Button>
            </>
          )}
        </Box>
      </StyledContainer>
    );
  }

  if (!isAuthorized || reauthNeeded) {
    return (
      <Box sx={{ minHeight: "100vh", color: "#E0E0E0" }}>
        <TopBar />
        <StyledContainer>
          <StyledInnerContainer>
            {error ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width="100%"
                py={4}
              >
                <Alert
                  severity="error"
                  sx={{
                    mt: 2,
                    mb: 4,
                    backgroundColor: "#333333",
                    color: "#E0E0E0",
                  }}
                >
                  {error}
                </Alert>
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width="100%"
                py={4}
              >
                <Typography variant="body1" color="text.secondary">
                  쓰레드 분석을 보시려면 쓰레드 권한을 주세요.
                </Typography>
                {!isAuthorized && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={manualAuthorize}
                    sx={{ mt: 2 }}
                  >
                    {reauthNeeded
                      ? "쓰레드 재인증하기"
                      : "쓰레드 권한 허용하기"}
                  </Button>
                )}
              </Box>
            )}
          </StyledInnerContainer>
        </StyledContainer>
      </Box>
    );
  }

  // C) Otherwise, show main page
  return (
    <Box sx={{ minHeight: "100vh", color: "#E0E0E0" }}>
      <TopBar />
      <StyledContainer>
        <StyledInnerContainer>
          <HeaderContainer>
            {loadingThreadsUser || loadingThreadsUser ? (
              <CircularProgress size={40} color="primary" />
            ) : threadsUser ? (
              <ThreadsUserCard
                threadsUser={threadsUser}
                threadsUserInsights={threadsUserInsights}
              />
            ) : null}
            {error && !reauthNeeded && (
              <Box m={2}>
                <Alert
                  severity="error"
                  sx={{ mb: 2, backgroundColor: "#333333", color: "#E0E0E0" }}
                >
                  {error}
                </Alert>
              </Box>
            )}
          </HeaderContainer>

          {loadingThreads ? (
            <Box mb={4} display="flex" justifyContent="center">
              <CircularProgress size={40} color="primary" />
            </Box>
          ) : (
            threadsMetrics &&
            threadsUser && (
              <Box
                margin={"20px 0px"}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <ThreadsMetricsCard
                  threads={threads}
                  threadsUser={threadsUser}
                  threadsMetrics={threadsMetrics || undefined}
                />
              </Box>
            )
          )}
          {/* Threads Report Section */}
          {!(threads.length > 0) ? null : loadingForThreadsReport ? (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              margin={1}
            >
              <CircularProgress size={20} />
            </Box>
          ) : (
            threadsReport &&
            threadsUser && (
              <>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGenerateThreadsReport}
                    disabled={
                      loadingAuthAndCredentials ||
                      loadingForThreadsReport ||
                      authCodeLoading ||
                      threads.length === 0
                    }
                  >
                    쓰레드 리포트 생성하기
                  </Button>
                </Box>
                <Box
                  m={2}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <ThreadsReportCard threadsReport={threadsReport} />
                </Box>
              </>
            )
          )}

          {/* 
            E) Threads List with a sort select. 
          */}
          {!loadingThreads && threads.length > 0 && (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width="100%"
              mb={2}
            >
              <Select
                value={threadsSortType}
                onChange={(e) =>
                  setThreadsSortType(e.target.value as ThreadsSortTypes)
                }
                sx={{
                  backgroundColor: "#1E1E1E",
                  color: "#E0E0E0",
                  "& .MuiSelect-icon": { color: "#E0E0E0" },
                }}
              >
                <MenuItem value="date">시간</MenuItem>
                <MenuItem value="views">조회수</MenuItem>
                <MenuItem value="likes">좋아요</MenuItem>
                <MenuItem value="replies">답글</MenuItem>
                <MenuItem value="reposts">리포스트</MenuItem>
                <MenuItem value="shares">공유</MenuItem>
              </Select>
            </Box>
          )}

          <ThreadsContainer threads={sortedThreads} loading={loadingThreads} />
        </StyledInnerContainer>
      </StyledContainer>
    </Box>
  );
};

export default ThreadsPage;
