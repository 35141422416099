import React from "react";
import WordCloud from "react-wordcloud";
import ResizeObserverErrorBoundary from "../ResizeObserverErrorBoundary";
import {
  Card,
  CardContent,
  Typography,
  styled,
  Box,
  Grid,
  CardProps,
  useMediaQuery,
  Divider,
  Chip,
} from "@mui/material";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts/core";
import {
  BarChart,
  BarSeriesOption,
  PieChart,
  PieSeriesOption,
} from "echarts/charts";
import {
  GridComponent,
  GridComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  TitleComponent,
  TitleComponentOption,
  LegendComponent,
  LegendComponentOption,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import {
  Thread,
  ThreadsUser,
  ThreadsMetrics,
} from "../../../../functions/src/types/threadsTypes";
import { PRIMARY_COLOR } from "../../utils/threadsUtils";

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  BarChart,
  PieChart,
  CanvasRenderer,
]);

const MAX_ENTRIES = 5;

// Compose the ECharts option types into one master type
type ECOption = echarts.ComposeOption<
  | BarSeriesOption
  | PieSeriesOption
  | GridComponentOption
  | TooltipComponentOption
  | TitleComponentOption
  | LegendComponentOption
>;

// Convert a Map<string, number> to a format suitable for ECharts
function mapEntriesToChartDataStringNumber(
  entriesMap: Map<string, number> | undefined
): { name: string; value: number }[] {
  if (!entriesMap) return [];
  return Array.from(entriesMap.entries()).map(([key, val]) => ({
    name: key,
    value: val,
  }));
}

// Convert a Map<string, number> to an array of typed objects for hooking phrases or other textual counts
function mapEntriesToHookingArray(
  entriesMap: Map<string, number> | undefined
): { phrase: string; count: number }[] {
  if (!entriesMap) return [];
  return Array.from(entriesMap.entries()).map(([phrase, count]) => ({
    phrase,
    count,
  }));
}

// Convert a Map<string, number> to an array of typed objects for audience segments, etc.
function mapEntriesToSegmentArray(
  entriesMap: Map<string, number> | undefined
): { segment: string; cnt: number }[] {
  if (!entriesMap) return [];
  return Array.from(entriesMap.entries()).map(([segment, cnt]) => ({
    segment,
    cnt,
  }));
}

// Convert a Map<string, number> to an array of typed objects for virality factors, etc.
function mapEntriesToFactorArray(
  entriesMap: Map<string, number> | undefined
): { factor: string; count: number }[] {
  if (!entriesMap) return [];
  return Array.from(entriesMap.entries()).map(([factor, count]) => ({
    factor,
    count,
  }));
}

interface ThreadsMetricsCardProps extends CardProps {
  threads: Thread[];
  threadsUser: ThreadsUser;
  // The overall analysis for ALL threads (optional, so TS doesn't break)
  threadsMetrics?: ThreadsMetrics;
  // The textual analysis, if any
  threadsReport?: string;
}

const StyledCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  borderRadius: "12px",
  backgroundColor: "#1E1E1E",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
  transition: "all 0.2s ease-in-out",
});

const StyledBox = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  textAlign: "center",
  width: "100%",
  height: "95%",
  padding: 10,
  margin: 10,
  border: "2px solid #333",
  borderRadius: "30px",
});

const ThreadsMetricsCard: React.FC<ThreadsMetricsCardProps> = ({
  threads,
  threadsUser,
  threadsMetrics,
  ...cardProps
}) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  // Safely destructure data from optional threadsMetrics
  const tagsMap = threadsMetrics?.tags;
  // const keywordsMap = threadsMetrics?.keywords;
  const topicsMap = threadsMetrics?.topics;
  const categoriesMap = threadsMetrics?.categories;
  const hookingPhrasesMap = threadsMetrics?.hookingPhrases;
  const audienceSegmentsMap = threadsMetrics?.audienceSegments;
  const viralityFactorsMap = threadsMetrics?.viralityFactors;
  const avgViralScore = threadsMetrics?.avgViralScore;

  // Convert to chart-friendly arrays
  const tagsArray = mapEntriesToChartDataStringNumber(tagsMap).sort(
    (a, b) => b.value - a.value
  );
  // const keywordsArray = mapEntriesToChartDataStringNumber(keywordsMap).sort(
  //   (a, b) => b.value - a.value
  // );
  const topicsArray = mapEntriesToChartDataStringNumber(topicsMap).sort(
    (a, b) => b.value - a.value
  );
  const categoriesArray = mapEntriesToChartDataStringNumber(categoriesMap).sort(
    (a, b) => b.value - a.value
  );
  const hookingPhrasesArray = mapEntriesToHookingArray(hookingPhrasesMap).sort(
    (a, b) => b.count - a.count
  );
  const audienceSegmentsArray = mapEntriesToSegmentArray(
    audienceSegmentsMap
  ).sort((a, b) => b.cnt - a.cnt);
  const viralityFactorsArray = mapEntriesToFactorArray(viralityFactorsMap).sort(
    (a, b) => b.count - a.count
  );

  const topThreads = threads
    .sort(
      (a, b) =>
        (b.insights?.find((insight) => insight.name === "views")?.values[0]
          .value ?? 0) -
        (a.insights?.find((insight) => insight.name === "views")?.values[0]
          .value ?? 0)
    )
    .slice(0, 3);

  // Prepare word cloud data
  const wordCloudData = tagsArray.map((tag) => ({
    text: tag.name,
    value: tag.value,
  }));

  // Word cloud options
  const wordCloudOptions = {
    rotations: 2,
    rotationAngles: [0, 0] as [number, number],
    fontSizes: [16, 64] as [number, number],
    colors: [
      "#E0E0E0",
      "#C6C6C6",
      "#B1B1B1",
      "#B15656",
      "#B12A2A",
      "#8D2121",
      "#FF3C3C",
      PRIMARY_COLOR,
    ],
    fontFamily: "Noto Sans KR",
    maxWords: 100,
    options: {
      scale: "sqrt",
      spiral: "archimedean",
      transitionDuration: 1000,
    },
  };

  // Build pie chart for categories
  const pieOption: ECOption = React.useMemo(() => {
    return {
      title: {
        text: "카테고리",
        left: "center",
        textStyle: {
          fontFamily: "Noto Sans KR",
          fontSize: 25,
          fontWeight: 500,
          color: "#E0E0E0",
        },
      },
      textStyle: { color: "#E0E0E0" },
      tooltip: { trigger: "item" },
      visualMap: {
        show: false,
        min: -180,
        max: 200,
        inRange: {
          colorLightness: [0, 1],
        },
      },
      series: [
        {
          name: "카테고리",
          type: "pie",
          radius: "55%",
          center: ["50%", "55%"],
          roseType: "radius",
          padAngle: 15,
          label: {
            color: "rgba(255, 255, 255, 0.5)",
          },
          labelLine: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.3)",
            },
            smooth: 0.2,
            length: 5,
            length2: 10,
          },
          itemStyle: {
            color: PRIMARY_COLOR,
            shadowBlur: 1000,
            shadowColor: "rgba(255, 255, 255, 0.15)",
          },
          animationType: "scale",
          animationEasing: "elasticOut",
          animationDelay: function (idx) {
            return Math.random() * 200;
          },
          data: categoriesArray.map((item) => ({
            name: item.name,
            value: item.value,
          })),
        },
      ],
    } as ECOption; // we cast as ECOption to quiet TS
  }, [categoriesArray]);

  const audienceBarOption: ECOption = React.useMemo(() => {
    return {
      title: {
        text: "타겟 오디언스",
        left: "center",
        textStyle: {
          fontFamily: "Noto Sans KR",
          fontSize: 25,
          fontWeight: 500,
          color: "#E0E0E0",
        },
      },
      tooltip: { trigger: "axis" },
      xAxis: {
        type: "category",
        data: audienceSegmentsArray
          .slice(0, MAX_ENTRIES)
          .map((seg) => seg.segment),
        axisLabel: { color: "#E0E0E0" },
        axisLine: { lineStyle: { color: "#333" } },
      },
      yAxis: {
        type: "value",
        axisLabel: { color: "#E0E0E0" },
        axisLine: { lineStyle: { color: "#333" } },
        splitLine: { lineStyle: { color: "#333" } },
      },
      series: [
        {
          data: audienceSegmentsArray
            .slice(0, MAX_ENTRIES)
            .map((seg) => seg.cnt),
          type: "bar",
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: PRIMARY_COLOR },
                { offset: 1, color: "#FF090B" },
              ],
            },
          },
        },
      ],
    } as ECOption;
  }, [audienceSegmentsArray]);

  return (
    <StyledCard {...cardProps}>
      <CardContent sx={{ padding: "24px" }}>
        {/* Title */}
        <Box mb={2} display="flex" justifyContent="center">
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            @{threadsUser.username} 님의 쓰레드 분석
          </Typography>
        </Box>

        {/* (1) Overall Analysis Section */}
        <Grid container sx={{ justifyContent: "center" }}>
          {/* Top Section: Word Cloud for tags */}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            {tagsArray.length > 0 && (
              <Box
                style={{
                  border: "2px solid #333",
                  borderRadius: "30px",
                  width: "100%",
                  height: isSmallScreen ? 230 : 250,
                  margin: 10,
                }}
              >
                <ResizeObserverErrorBoundary>
                  <WordCloud words={wordCloudData} options={wordCloudOptions} />
                </ResizeObserverErrorBoundary>
              </Box>
            )}
          </Grid>
          {/* Second Section: Viral Score, Hookinng Phrases and Categories */}
          {/* Avg viral score if available */}
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{
              display: "flex",
            }}
          >
            {typeof avgViralScore === "number" && (
              <StyledBox>
                <Typography variant="h3" sx={{ fontWeight: 500, mb: 3 }}>
                  바이럴 스코어
                </Typography>
                <Typography variant="h1" sx={{ fontSize: "50px" }}>
                  <strong style={{ color: PRIMARY_COLOR }}>
                    {avgViralScore.toFixed(2)}
                  </strong>
                </Typography>
              </StyledBox>
            )}
          </Grid>
          {/* categories */}
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{
              display: "flex",
            }}
          >
            {categoriesArray.length > 0 && (
              <StyledBox>
                <ReactECharts
                  option={pieOption}
                  notMerge
                  lazyUpdate
                  style={{
                    width: "100%",
                    height: isSmallScreen ? "280px" : "320px",
                  }}
                />
              </StyledBox>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{
              display: "flex",
            }}
          >
            {audienceSegmentsArray.length > 0 && (
              <StyledBox>
                <ReactECharts
                  option={audienceBarOption}
                  notMerge
                  lazyUpdate
                  style={{
                    width: "100%",
                    height: isSmallScreen ? "280px" : "320px",
                  }}
                />
              </StyledBox>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{
              display: "flex",
            }}
          >
            {viralityFactorsArray.length > 0 && (
              <StyledBox>
                <Typography variant="h4" sx={{ fontWeight: 500, mb: 3 }}>
                  바이럴 요소
                </Typography>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="center"
                  alignContent="center"
                >
                  {viralityFactorsArray.slice(0, MAX_ENTRIES).map((vf, i) => (
                    <Box key={i} m={0.6}>
                      <Chip
                        label={`${vf.factor}: ${vf.count}`}
                        // make the background color a gradient normalized to the max from min
                        sx={{
                          backgroundColor: `rgba(192, 49, 43, ${
                            vf.count /
                            Math.max(
                              ...viralityFactorsArray.map((v) => v.count / 2)
                            )
                          })`,
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </StyledBox>
            )}
          </Grid>

          {/* Third Section: Target Audience, Virality Factors, Call-to-actions */}
          <Grid container>
            {/* Topics */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
              }}
            >
              {topicsArray.length > 0 && (
                <StyledBox>
                  <Typography variant="h4" sx={{ fontWeight: 500, mb: 3 }}>
                    가장 많이 쓰인 주제
                  </Typography>
                  {topicsArray.slice(0, MAX_ENTRIES).map((topic, index) => (
                    <Box key={index} mb={1}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontStyle: "italic" }}
                      >
                        “{topic.name}”
                      </Typography>
                    </Box>
                  ))}
                </StyledBox>
              )}
            </Grid>
            {/* Hooking Phrases */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
              }}
            >
              {hookingPhrasesArray.length > 0 && (
                <StyledBox>
                  <Typography variant="h4" sx={{ fontWeight: 500, mb: 3 }}>
                    가장 많이 쓰인 훅
                  </Typography>
                  {hookingPhrasesArray
                    .slice(0, MAX_ENTRIES)
                    .map((hp, index) => (
                      <Box key={index} mb={1}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontStyle: "italic" }}
                        >
                          “{hp.phrase}”
                        </Typography>
                      </Box>
                    ))}
                </StyledBox>
              )}
            </Grid>
          </Grid>

          {/* (2) Top 3 Most-Liked/Viewed Threads Section */}
          <Grid item xs={12}>
            {topThreads && topThreads.length > 0 && (
              <StyledBox>
                <Typography variant="h4" sx={{ fontWeight: 600, mb: 2 }}>
                  가장 바이럴한 쓰레드
                </Typography>
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  {topThreads.map((thread) => (
                    <Card
                      key={thread.id}
                      sx={{
                        m: 1,
                        backgroundColor: "#202020",
                        flex: "1 1 300px",
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h5" sx={{ p: 1 }}>
                          <strong>
                            {thread.text ? thread.text.split("\n")[0] : null}
                          </strong>
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: 500, mt: 1 }}
                        >
                          {JSON.stringify(
                            thread.insights
                              ?.map(
                                (insight) =>
                                  `${insight.title}: ${insight.values[0].value}`
                              )
                              .join(" | ")
                          )}
                        </Typography>
                        <Divider
                          orientation="horizontal"
                          variant="middle"
                          flexItem={true}
                          sx={{ margin: 1 }}
                        />
                        <Box sx={{ p: 1 }}>
                          <Typography>
                            <strong>바이럴 요소: </strong>
                            {thread.metadata?.expected_virality_factors?.join(
                              ", "
                            )}{" "}
                            <strong>
                              (바이럴 스코어: {thread.metadata?.viral_score})
                            </strong>
                          </Typography>
                        </Box>
                        <Box sx={{ p: 1 }}>
                          <Typography>
                            <strong>후킹: </strong>
                            {thread.metadata?.hooking_phrases?.join(", ")}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              </StyledBox>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default ThreadsMetricsCard;
