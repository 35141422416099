import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
// import FacebookIcon from '@mui/icons-material/Facebook';
// import { Google } from "@mui/icons-material";
import { useState } from "react";
import SignUpComponent from "./SignUpComponent";
import { Google } from "@mui/icons-material";
import { log } from "../../config/firebase";

interface SignInProps {
    callback?: () => void;
}

const SignInComponent: React.FC<SignInProps> = ({ callback }) => {
    const { signInWithEmailAndPassword, signInWithGoogle } = useAuth();
    const [showSignUp, setShowSignUp] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    if (showSignUp) {
        return <SignUpComponent callback={() => {
            setShowSignUp(false);
            if (callback) {
                callback();
            }
        }} />;
    }

    const handleEmailSignIn = async () => {
        try {
            await signInWithEmailAndPassword(email, password);
            log('sign_in', { method: 'email' });
            // logEvent({
            //     category: "Authentication",
            //     action: "Sign In",
            //     label: 'email'
            // })
            if (callback) callback();
        } catch (error: any) {
            console.error("Error signing in with email:", error);
            setError(error.message.toString());
            log('sign_in_fail', { method: 'email' });
            // logEvent({
            //     category: "Authentication",
            //     action: "Sign In Fail",
            //     label: 'email'
            // })
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            await signInWithGoogle();
            log('sign_in', { method: 'google' });
            // logEvent({
            //     category: "Authentication",
            //     action: "Sign In",
            //     label: 'google'
            // })
            if (callback) callback();
        } catch (error) {
            console.error('Error signing in with Google:', error);
            log('sign_in_fail', { method: 'google' });
            // logEvent({
            //     category: "Authentication",
            //     action: "Sign In Fail",
            //     label: 'google'
            // })
        }
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ pt: 7, pb: 7 }} width="300px" maxWidth="100%">
            <Box p={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Typography variant="h6">
                    로그인 후 사용 가능합니다
                </Typography>
                <Typography variant="subtitle2" fontWeight={600} pt={1}>
                    커리어해커 알렉스
                </Typography>
            </Box>
            <Box width="200px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <TextField
                    label="이메일"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    required
                    fullWidth
                    margin="dense"
                />
                <TextField
                    label="비밀번호"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    required
                    fullWidth
                    margin="dense"
                />
                <Button variant="contained" onClick={handleEmailSignIn} sx={{ m: 1, alignItems: "center" }} fullWidth>
                    로그인
                </Button>
                <Typography variant="caption" p={1}> <b>또는</b> </Typography>
                <Button variant="outlined" startIcon={<Google />} onClick={handleGoogleSignIn} sx={{ m: 1, fontSize: '9px' }} fullWidth>
                    구글 계정으로 로그인
                </Button>
                {error && (
                    <Typography variant="caption" color="error">
                        {error}
                    </Typography>
                )}
            </Box>
            <Divider variant="fullWidth" sx={{ width: '250px', m: 1 }} />
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <Typography variant="caption" sx={{ m: 1 }}>
                    혹시 방문이 처음이신가요?{" "}
                </Typography>
                <Typography
                    variant="caption"
                    onClick={() => setShowSignUp(true)}
                    sx={{ textDecoration: 'underline', cursor: 'pointer', color: 'primary.main' }}
                >
                    <b>회원가입</b>
                </Typography>
            </Box>
        </Box>
    )
}

export default SignInComponent;
