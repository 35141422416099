import { PersonasProvider } from "../../context/PersonasContext";
import { Outlet } from "react-router-dom";

const PromptLayout = () => {
  return (
    <PersonasProvider>
      <Outlet />
    </PersonasProvider>
  );
};

export default PromptLayout;
