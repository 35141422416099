import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  styled,
  Chip,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TopBar from "../navigations/TopBar";
import { Prompt, Comment } from "../../../functions/src/types/promptsTypes";
import { FirebaseError } from "firebase/app";
import { getPrompts, likePrompt, getComments } from "../api/promptApi";
import { useAuth } from "../context/AuthContext";
import SignInModal from "../components/profile/SignInModal";
import { log } from "../config/firebase";
import PromptCard from "../components/promptExplorer/PromptCard";
import RichContentCard from "../components/others/RichContentCard";
import { getPromptTagColor } from "../utils/colors";

interface ExtendedPrompt extends Prompt {
  likes?: number;
}

const MOST_COMMON_TAGS = [
  "마케팅",
  "커리어",
  "창의성",
  "창업",
  "자기계발",
  "디지털 마케팅",
  "리더십",
  "디자인",
  "콘텐츠 제작",
  "데이터 분석",
  "유튜브",
  "개발자",
];

const StyledContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "flex-start",
  flexWrap: "wrap",
  flexGrow: 1,
  maxWidth: "100%",
  padding: "24px",
  margin: "24px auto",
  gap: "24px",
  backgroundColor: "#121212",
});

const StyledSearchBar = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "end",
  width: "80%",
  maxWidth: "600px",
  margin: "30px auto",
  padding: "16px 24px",
  borderRadius: "12px",
  backgroundColor: "#1E1E1E",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
  "& .MuiTextField-root": {
    "& .MuiOutlinedInput-root": {
      color: "#E0E0E0",
      "&:hover fieldset": {
        borderColor: "#444444",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#666666",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#999999",
    },
  },
  "& .MuiDivider-root": {
    backgroundColor: "#333333",
  },
  "& .MuiSvgIcon-root": {
    color: "#999999",
  },
});

const StyledChip = styled(Chip)({
  margin: "4px",
  backgroundColor: "#333333",
  color: "#E0E0E0",
  "& .MuiChip-label": {
    fontSize: "0.75rem",
    fontFamily: "'Inter', sans-serif",
  },
  "&:hover": {
    backgroundColor: "#444444",
    transform: "scale(1.05)",
  },
  transition: "all 0.2s ease-in-out",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
});

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "12px",
    backgroundColor: "#1E1E1E",
    color: "#E0E0E0",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
  },
  "& .MuiDialogContent-root": {
    padding: "24px",
  },
});

const LoadMoreButton = styled(Button)({
  margin: "24px",
  padding: "12px 24px",
  borderRadius: "12px",
  backgroundColor: "#1E1E1E",
  color: "#E0E0E0",
  "&:hover": {
    backgroundColor: "#252525",
  },
  transition: "all 0.2s ease-in-out",
});

const AllPromptsPage: React.FC = () => {
  const { user, isAuthenticated } = useAuth();
  const [prompts, setPrompts] = useState<ExtendedPrompt[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [promptComments, setPromptComments] = useState<
    Record<string, Comment[]>
  >({});
  const [loadingComments, setLoadingComments] = useState<
    Record<string, boolean>
  >({});
  const [loadingLikes, setLoadingLikes] = useState<Record<string, boolean>>({});
  const [error, setError] = useState<string | null>(null);
  const [selectedPrompt, setSelectedPrompt] = useState<ExtendedPrompt | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [searchQueryString, setSearchQueryString] = useState<string>("");
  const [searchQueries, setSearchQueries] = useState<string[]>([]);

  const fetchPrompts = useCallback(
    async (lastPromptId: string | null) => {
      setIsLoading(true);
      setError(null);
      try {
        const data = await getPrompts(
          lastPromptId || undefined,
          searchQueries.length > 0 ? searchQueries : undefined
        );
        if (Array.isArray(data)) {
          if (lastPromptId === null) {
            setPrompts(data as ExtendedPrompt[]);
          } else {
            setPrompts((prev) => [...prev, ...(data as ExtendedPrompt[])]);
          }
        }
      } catch (error) {
        console.error("Error fetching prompts:", error);
        setError(
          error instanceof FirebaseError
            ? error.message
            : "Failed to fetch prompts"
        );
      } finally {
        setIsLoading(false);
      }
    },
    [searchQueries]
  );

  useEffect(() => {
    log("prompt_explorer.page_view");
    fetchPrompts(null);
  }, [fetchPrompts]);

  useEffect(() => {
    const fetchComments = async () => {
      const commentPromises = prompts.map(async (prompt) => {
        const promptId = prompt.id;
        if (!promptId || promptComments[promptId] || loadingComments[promptId])
          return;

        setLoadingComments((prev) => ({ ...prev, [promptId]: true }));
        try {
          const comments = await getComments(promptId);
          setPromptComments((prev) => ({
            ...prev,
            [promptId]: comments as Comment[],
          }));
        } catch (error) {
          console.error(
            `Error fetching comments for prompt ${promptId}:`,
            error
          );
        } finally {
          setLoadingComments((prev) => ({ ...prev, [promptId]: false }));
        }
      });
      await Promise.all(commentPromises);
    };

    if (prompts.length > 0) {
      fetchComments();
    }
  }, [prompts, promptComments, loadingComments]);

  useEffect(() => {
    fetchPrompts(null);
  }, [searchQueries, fetchPrompts]);

  const handleLoadMore = () => {
    fetchPrompts(prompts[prompts.length - 1]?.id ?? null);
    log("prompt_explorer.load_more_prompts_button_click");
  };

  const handleCardClick = (prompt: ExtendedPrompt) => {
    const promptId = prompt.id ?? null;
    if (!user || !isAuthenticated) {
      log("prompt_explorer.prompt_card_open_sign_in", { prompt: promptId });
      setOpenSignInModal(true);
      return;
    }
    setSelectedPrompt(prompt);
    setIsModalOpen(true);
    log("prompt_explorer.prompt_card_open", { prompt: prompt.id });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedPrompt(null);
    log("prompt_explorer.prompt_card_close");
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      if (searchQueryString.trim()) {
        setSearchQueries((prev) => [...prev, searchQueryString.trim()]);
        setSearchQueryString("");
        log("prompt_explorer.add_new_prompt_filter_tag", {
          tag: searchQueryString.trim(),
        });
      }
    }
  };

  const handleDeleteQuery = (queryToDelete: string) => {
    setSearchQueries((prev) => prev.filter((query) => query !== queryToDelete));
  };

  const handleClickAddTag = (tag: string) => {
    setSearchQueries((prev) => [...prev, tag]);
    log("prompt_explorer.prompt_filter_tag_click", { tag });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#121212",
        minHeight: "100vh",
        color: "#E0E0E0",
      }}
    >
      <TopBar />
      <StyledSearchBar>
        {searchQueries.map((query, index) => (
          <Chip
            key={`query_${index}`}
            label={query}
            onDelete={() => handleDeleteQuery(query)}
            sx={{
              m: 0.5,
              backgroundColor: "#333333",
              color: "#E0E0E0",
              "& .MuiChip-deleteIcon": {
                color: "#999999",
                "&:hover": {
                  color: "#E0E0E0",
                },
              },
            }}
          />
        ))}
        <TextField
          label="찾으시는 프롬프트 키워드를 입력해주세요"
          variant="standard"
          fullWidth
          value={searchQueryString}
          onChange={(e) => setSearchQueryString(e.target.value)}
          onKeyPress={handleKeyPress}
          sx={{
            "& .MuiInput-underline:before": {
              borderBottomColor: "#333333",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "#444444",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#666666",
            },
            "& .MuiInputBase-input": {
              color: "#E0E0E0",
            },
            "& .MuiInputLabel-root": {
              color: "#999999",
              "&.Mui-focused": {
                color: "#E0E0E0",
              },
            },
          }}
        />
        <Divider orientation="vertical" sx={{ height: "30px", mx: 2 }} />
        <SearchIcon />
      </StyledSearchBar>

      <Box
        display="flex"
        flexWrap="wrap"
        m={2}
        mb={0}
        justifyContent="center"
        alignItems="center"
      >
        {MOST_COMMON_TAGS.map((tag, index) => (
          <StyledChip
            key={index}
            label={tag}
            sx={{
              backgroundColor: getPromptTagColor(tag),
              color: "black",
            }}
            onClick={() => handleClickAddTag(tag)}
          />
        ))}
      </Box>

      <StyledContainer>
        {prompts.map((prompt, index) => (
          <RichContentCard
            key={index}
            onClickCard={() => handleCardClick(prompt)}
            persona={prompt.gpt_persona || ""}
            title={prompt.prompt_title}
            updatedAt={prompt.updatedAt ?? null}
            mainText={prompt.prompt}
            tags={prompt.prompt_tags}
            likes={prompt.likes}
            onLike={async (e) => {
              const promptId = prompt.id;
              if (!promptId) return;
              if (!user || !isAuthenticated) {
                setOpenSignInModal(true);
                return;
              }
              if (loadingLikes[promptId]) return;

              setLoadingLikes((prev) => ({
                ...prev,
                [promptId]: true,
              }));
              try {
                await likePrompt(promptId);
                setPrompts((prevPrompts) =>
                  prevPrompts.map((p) =>
                    p.id === promptId ? { ...p, likes: (p.likes || 0) + 1 } : p
                  )
                );
                log("prompt_explorer.like_prompt", {
                  prompt: promptId,
                });
              } catch (error) {
                console.error("Error liking prompt:", error);
              } finally {
                setLoadingLikes((prev) => ({
                  ...prev,
                  [promptId]: false,
                }));
              }
            }}
            showLikeButton={true}
            isLikeLoading={loadingLikes[prompt.id ?? ""]}
            showCommentsCount={true}
            commentsCount={promptComments[prompt.id ?? ""]?.length ?? 0}
            onClickTag={(tag: string) => {
              handleClickAddTag(tag);
            }}
          />
        ))}

        {isLoading && <CircularProgress sx={{ color: "#E0E0E0" }} />}

        {error && (
          <Typography sx={{ color: "#ff6b6b", m: 2 }}>{error}</Typography>
        )}

        {!isLoading && !error && prompts.length > 0 && (
          <LoadMoreButton onClick={handleLoadMore} disabled={isLoading}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "'Inter', sans-serif",
                fontWeight: 500,
              }}
            >
              더 보기
            </Typography>
          </LoadMoreButton>
        )}

        <StyledDialog
          open={isModalOpen}
          onClose={handleCloseModal}
          maxWidth="sm"
          fullWidth
        >
          <DialogContent>
            {selectedPrompt && <PromptCard prompt={selectedPrompt} />}
          </DialogContent>
        </StyledDialog>

        <SignInModal open={openSignInModal} setOpen={setOpenSignInModal} />
      </StyledContainer>
    </Box>
  );
};

export default AllPromptsPage;
