import React, { createContext, useEffect, useState } from "react";
import { Goal, Persona } from "types";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../config/firebase";

interface PersonasContextProps {
  personas: Persona[];
  goals: Goal[];
}

export const PersonasContext = createContext<PersonasContextProps>({
  personas: [],
  goals: [],
});

interface PersonasProviderProps {
  children: React.ReactNode;
}

export const PersonasProvider: React.FC<PersonasProviderProps> = ({
  children,
}) => {
  const [personas, setPersonas] = useState<Persona[]>([]);
  const [goals, setGoals] = useState<Goal[]>([]);

  useEffect(() => {
    const functions = getFunctions(app, "us-west1");
    const getPersonasFunction = httpsCallable(functions, "getPersonas");
    const getGoalsFunction = httpsCallable(functions, "getGoals");

    Promise.all([getPersonasFunction(), getGoalsFunction()])
      .then(([personasResult, goalsResult]) => {
        setPersonas(personasResult.data as Persona[]);
        setGoals(goalsResult.data as Goal[]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []); // The empty array ensures this effect runs only once after the initial render

  return (
    <PersonasContext.Provider value={{ personas, goals }}>
      {children}
    </PersonasContext.Provider>
  );
};
