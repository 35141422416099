import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  styled,
  Chip,
  TextField,
  Divider,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import TopBar from "../navigations/TopBar";
import { Prompt } from "../../../functions/src/types/promptsTypes";
import { FirebaseError } from "firebase/app";
import { getMyPrompts, createMyPrompt } from "../api/promptApi";
import { useAuth } from "../context/AuthContext";
import SignInModal from "../components/profile/SignInModal";
import { log } from "../config/firebase";
import PromptCard from "../components/promptExplorer/PromptCard";
import { getPromptTagColor } from "../utils/colors";

interface ExtendedPrompt extends Prompt {
  likes?: number;
  expanded?: boolean;
}

const MOST_COMMON_TAGS = [
  "마케팅",
  "커리어",
  "창의성",
  "창업",
  "자기계발",
  "디지털 마케팅",
  "리더십",
  "디자인",
  "콘텐츠 제작",
  "데이터 분석",
  "유튜브",
  "개발자",
];

const StyledContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "flex-start",
  flexWrap: "wrap",
  flexGrow: 1,
  maxWidth: "100%",
  padding: "24px",
  margin: "24px auto",
  gap: "24px",
  backgroundColor: "#121212",
});

const StyledSearchBar = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "end",
  width: "80%",
  maxWidth: "600px",
  margin: "30px auto",
  padding: "16px 24px",
  borderRadius: "12px",
  backgroundColor: "#1E1E1E",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
  "& .MuiTextField-root": {
    "& .MuiOutlinedInput-root": {
      color: "#E0E0E0",
      "&:hover fieldset": {
        borderColor: "#444444",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#666666",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#999999",
    },
  },
  "& .MuiDivider-root": {
    backgroundColor: "#333333",
  },
  "& .MuiSvgIcon-root": {
    color: "#999999",
  },
});

const StyledChip = styled(Chip)({
  margin: "4px",
  backgroundColor: "#333333",
  color: "#E0E0E0",
  "& .MuiChip-label": {
    fontSize: "0.75rem",
    fontFamily: "'Inter', sans-serif",
  },
  "&:hover": {
    backgroundColor: "#444444",
    transform: "scale(1.05)",
  },
  transition: "all 0.2s ease-in-out",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
});

interface ExpandMoreProps {
  expanded: boolean;
  onClick?: () => void;
  'aria-expanded'?: boolean;
  'aria-label'?: string;
}

const ExpandMoreIconStyled = styled(
  ({ expanded, ...other }: ExpandMoreProps) => (
    <IconButton {...other}>
      <ExpandMoreIcon />
    </IconButton>
  )
)(({ expanded }: ExpandMoreProps) => ({
  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.3s',
  color: "#E0E0E0",
}));

const PromptCardStyled = styled(Card)({
  width: "100%",
  maxWidth: "800px",
  margin: "16px auto",
  backgroundColor: "#1E1E1E",
  color: "#E0E0E0",
  borderRadius: "12px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.4)",
    backgroundColor: "#252525",
  },
});

// Initial form values for the create prompt modal
const initialFormValues = {
  prompt_title: "",
  gpt_persona: "",
  user_persona: "",
  user_goal: "",
  user_task: "",
  prompt: "",
  prompt_tags: "",
};

// Form validation errors
interface FormErrors {
  prompt_title?: string;
  gpt_persona?: string;
  user_persona?: string;
  user_goal?: string;
  user_task?: string;
  prompt?: string;
}

const PromptsPage: React.FC = () => {
  const { user, isAuthenticated } = useAuth();
  const [prompts, setPrompts] = useState<ExtendedPrompt[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [searchQueryString, setSearchQueryString] = useState<string>("");
  const [searchQueries, setSearchQueries] = useState<string[]>([]);
  
  // State for the create prompt modal
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const fetchPrompts = useCallback(
    async (lastPromptId: string | null) => {
      setIsLoading(true);
      setError(null);
      try {
        const data = await getMyPrompts(
          lastPromptId || undefined,
          searchQueries.length > 0 ? searchQueries : undefined
        );
        if (Array.isArray(data)) {
          if (lastPromptId === null) {
            setPrompts(data.map(prompt => ({ ...prompt, expanded: false })) as ExtendedPrompt[]);
          } else {
            setPrompts((prev) => [...prev, ...(data.map(prompt => ({ ...prompt, expanded: false })) as ExtendedPrompt[])]);
          }
        }
      } catch (error) {
        console.error("Error fetching prompts:", error);
        setError(
          error instanceof FirebaseError
            ? error.message
            : "Failed to fetch prompts"
        );
      } finally {
        setIsLoading(false);
      }
    },
    [searchQueries]
  );

  useEffect(() => {
    log("prompts.page_view");
    fetchPrompts(null);
  }, [fetchPrompts]);

  useEffect(() => {
    fetchPrompts(null);
  }, [searchQueries, fetchPrompts]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      if (searchQueryString.trim()) {
        setSearchQueries((prev) => [...prev, searchQueryString.trim()]);
        setSearchQueryString("");
        log("prompts.add_new_prompt_filter_tag", {
          tag: searchQueryString.trim(),
        });
      }
    }
  };

  const handleDeleteQuery = (queryToDelete: string) => {
    setSearchQueries((prev) => prev.filter((query) => query !== queryToDelete));
  };

  const handleClickAddTag = (tag: string) => {
    setSearchQueries((prev) => [...prev, tag]);
    log("prompts.prompt_filter_tag_click", { tag });
  };

  const handleExpandClick = (index: number) => {
    setPrompts(prevPrompts => 
      prevPrompts.map((prompt, i) => 
        i === index ? { ...prompt, expanded: !prompt.expanded } : prompt
      )
    );
  };

  // Handle form input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    // Clear error when user types
    if (formErrors[name as keyof FormErrors]) {
      setFormErrors({
        ...formErrors,
        [name]: undefined,
      });
    }
  };

  // Validate form
  const validateForm = () => {
    const errors: FormErrors = {};
    if (!formValues.prompt_title.trim()) {
      errors.prompt_title = "Title is required";
    }
    if (!formValues.gpt_persona.trim()) {
      errors.gpt_persona = "GPT Persona is required";
    }
    if (!formValues.user_persona.trim()) {
      errors.user_persona = "User Persona is required";
    }
    if (!formValues.user_goal.trim()) {
      errors.user_goal = "User Goal is required";
    }
    if (!formValues.user_task.trim()) {
      errors.user_task = "User Task is required";
    }
    if (!formValues.prompt.trim()) {
      errors.prompt = "Prompt content is required";
    }
    return errors;
  };

  // Handle form submission
  const handleSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setIsSubmitting(true);
    setSubmitSuccess(false);
    
    try {
      // Convert comma-separated tags to array
      const promptTags = formValues.prompt_tags
        .split(',')
        .map(tag => tag.trim())
        .filter(tag => tag.length > 0);
      
      // Create prompt data
      const promptData = {
        ...formValues,
        prompt_tags: promptTags,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      
      await createMyPrompt(promptData);
      setSubmitSuccess(true);
      
      // Reset form and close modal after successful submission
      setTimeout(() => {
        setFormValues(initialFormValues);
        setOpenCreateModal(false);
        // Refresh prompts list
        fetchPrompts(null);
      }, 1500);
    } catch (error) {
      console.error("Error creating prompt:", error);
      setError(
        error instanceof FirebaseError
          ? error.message
          : "Failed to create prompt"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle modal close
  const handleCloseModal = () => {
    setOpenCreateModal(false);
    setFormValues(initialFormValues);
    setFormErrors({});
    setSubmitSuccess(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#121212",
        minHeight: "100vh",
        color: "#E0E0E0",
      }}
    >
      <TopBar />
      <Box textAlign="center" mt={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          ChatGPT Prompts
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" mb={4}>
          A collection of useful ChatGPT prompts for various purposes
        </Typography>
      </Box>
      
      <StyledSearchBar>
        {searchQueries.map((query, index) => (
          <Chip
            key={`query_${index}`}
            label={query}
            onDelete={() => handleDeleteQuery(query)}
            sx={{
              m: 0.5,
              backgroundColor: "#333333",
              color: "#E0E0E0",
              "& .MuiChip-deleteIcon": {
                color: "#999999",
                "&:hover": {
                  color: "#E0E0E0",
                },
              },
            }}
          />
        ))}
        <TextField
          label="Search prompts by keyword"
          variant="standard"
          fullWidth
          value={searchQueryString}
          onChange={(e) => setSearchQueryString(e.target.value)}
          onKeyPress={handleKeyPress}
          sx={{
            "& .MuiInput-underline:before": {
              borderBottomColor: "#333333",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "#444444",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#666666",
            },
            "& .MuiInputBase-input": {
              color: "#E0E0E0",
            },
            "& .MuiInputLabel-root": {
              color: "#999999",
              "&.Mui-focused": {
                color: "#E0E0E0",
              },
            },
          }}
        />
        <Divider orientation="vertical" sx={{ height: "30px", mx: 2 }} />
        <SearchIcon />
      </StyledSearchBar>

      <Box
        display="flex"
        flexWrap="wrap"
        m={2}
        mb={0}
        justifyContent="center"
        alignItems="center"
      >
        {MOST_COMMON_TAGS.map((tag, index) => (
          <StyledChip
            key={index}
            label={tag}
            sx={{
              backgroundColor: getPromptTagColor(tag),
              color: "black",
            }}
            onClick={() => handleClickAddTag(tag)}
          />
        ))}
      </Box>

      <Box maxWidth="900px" mx="auto" p={3}>
        {/* Create Button - Only visible in DEV mode */}
        {process.env.NODE_ENV === "development" && (
          <Box display="flex" justifyContent="center" mb={4}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setOpenCreateModal(true)}
              sx={{
                borderRadius: "8px",
                padding: "10px 20px",
                backgroundColor: "#2A2A2A",
                color: "#E0E0E0",
                "&:hover": {
                  backgroundColor: "#3A3A3A",
                },
              }}
            >
              Create New Prompt
            </Button>
          </Box>
        )}

        {isLoading && (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress sx={{ color: "#E0E0E0" }} />
          </Box>
        )}

        {error && (
          <Typography sx={{ color: "#ff6b6b", m: 2, textAlign: "center" }}>
            {error}
          </Typography>
        )}

        {!isLoading && !error && prompts.length === 0 && (
          <Typography sx={{ color: "#E0E0E0", m: 2, textAlign: "center" }}>
            No prompts found. Try adjusting your search criteria.
          </Typography>
        )}

        {prompts.map((prompt, index) => (
          <PromptCardStyled key={index}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography variant="h6" component="h2">
                    {prompt.prompt_title}
                  </Typography>
                  {prompt.gpt_persona && (
                    <Typography variant="subtitle1" color="text.secondary">
                      {prompt.gpt_persona}
                    </Typography>
                  )}
                </Box>
                <ExpandMoreIconStyled
                  expanded={!!prompt.expanded}
                  onClick={() => handleExpandClick(index)}
                  aria-expanded={!!prompt.expanded}
                  aria-label="show more"
                />
              </Box>
              
              <Box mt={2} mb={1}>
                {prompt.prompt_tags?.map((tag, tagIndex) => (
                  <StyledChip
                    key={tagIndex}
                    label={tag}
                    size="small"
                    sx={{
                      backgroundColor: getPromptTagColor(tag),
                      color: "black",
                      mr: 0.5,
                      mb: 0.5,
                    }}
                    onClick={() => handleClickAddTag(tag)}
                  />
                ))}
              </Box>
              
              <Collapse in={!!prompt.expanded} timeout="auto" unmountOnExit>
                <Box mt={2}>
                  <PromptCard prompt={prompt} />
                </Box>
              </Collapse>
            </CardContent>
          </PromptCardStyled>
        ))}
      </Box>

      <SignInModal open={openSignInModal} setOpen={setOpenSignInModal} />

      {/* Create Prompt Modal */}
      <Dialog
        open={openCreateModal}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: "#1E1E1E",
            color: "#E0E0E0",
            borderRadius: "12px",
          },
        }}
      >
        <DialogTitle sx={{ borderBottom: "1px solid #333333" }}>
          Create New Prompt
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          {submitSuccess ? (
            <Box textAlign="center" py={3}>
              <Typography variant="h6" color="success.main" gutterBottom>
                Prompt created successfully!
              </Typography>
              <Typography variant="body1">
                Your prompt has been saved to the database.
              </Typography>
            </Box>
          ) : (
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <FormControl
                fullWidth
                margin="normal"
                error={!!formErrors.prompt_title}
                variant="outlined"
              >
                <InputLabel htmlFor="prompt_title">Prompt Title</InputLabel>
                <OutlinedInput
                  id="prompt_title"
                  name="prompt_title"
                  label="Prompt Title"
                  value={formValues.prompt_title}
                  onChange={handleInputChange}
                  sx={{
                    color: "#E0E0E0",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#444444",
                    },
                  }}
                />
                {formErrors.prompt_title && (
                  <FormHelperText>{formErrors.prompt_title}</FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                margin="normal"
                error={!!formErrors.gpt_persona}
                variant="outlined"
              >
                <InputLabel htmlFor="gpt_persona">GPT Persona</InputLabel>
                <OutlinedInput
                  id="gpt_persona"
                  name="gpt_persona"
                  label="GPT Persona"
                  value={formValues.gpt_persona}
                  onChange={handleInputChange}
                  sx={{
                    color: "#E0E0E0",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#444444",
                    },
                  }}
                />
                {formErrors.gpt_persona && (
                  <FormHelperText>{formErrors.gpt_persona}</FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                margin="normal"
                error={!!formErrors.user_persona}
                variant="outlined"
              >
                <InputLabel htmlFor="user_persona">User Persona</InputLabel>
                <OutlinedInput
                  id="user_persona"
                  name="user_persona"
                  label="User Persona"
                  value={formValues.user_persona}
                  onChange={handleInputChange}
                  sx={{
                    color: "#E0E0E0",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#444444",
                    },
                  }}
                />
                {formErrors.user_persona && (
                  <FormHelperText>{formErrors.user_persona}</FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                margin="normal"
                error={!!formErrors.user_goal}
                variant="outlined"
              >
                <InputLabel htmlFor="user_goal">User Goal</InputLabel>
                <OutlinedInput
                  id="user_goal"
                  name="user_goal"
                  label="User Goal"
                  value={formValues.user_goal}
                  onChange={handleInputChange}
                  sx={{
                    color: "#E0E0E0",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#444444",
                    },
                  }}
                />
                {formErrors.user_goal && (
                  <FormHelperText>{formErrors.user_goal}</FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                margin="normal"
                error={!!formErrors.user_task}
                variant="outlined"
              >
                <InputLabel htmlFor="user_task">User Task</InputLabel>
                <OutlinedInput
                  id="user_task"
                  name="user_task"
                  label="User Task"
                  value={formValues.user_task}
                  onChange={handleInputChange}
                  sx={{
                    color: "#E0E0E0",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#444444",
                    },
                  }}
                />
                {formErrors.user_task && (
                  <FormHelperText>{formErrors.user_task}</FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                margin="normal"
                error={!!formErrors.prompt}
                variant="outlined"
              >
                <InputLabel htmlFor="prompt">Prompt Content</InputLabel>
                <OutlinedInput
                  id="prompt"
                  name="prompt"
                  label="Prompt Content"
                  multiline
                  rows={6}
                  value={formValues.prompt}
                  onChange={handleInputChange}
                  sx={{
                    color: "#E0E0E0",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#444444",
                    },
                  }}
                />
                {formErrors.prompt && (
                  <FormHelperText>{formErrors.prompt}</FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
              >
                <InputLabel htmlFor="prompt_tags">Tags (comma-separated)</InputLabel>
                <OutlinedInput
                  id="prompt_tags"
                  name="prompt_tags"
                  label="Tags (comma-separated)"
                  value={formValues.prompt_tags}
                  onChange={handleInputChange}
                  sx={{
                    color: "#E0E0E0",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#444444",
                    },
                  }}
                />
                <FormHelperText>
                  Enter tags separated by commas (e.g., "marketing, career, creativity")
                </FormHelperText>
              </FormControl>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px solid #333333", p: 2 }}>
          <Button
            onClick={handleCloseModal}
            sx={{
              color: "#999999",
              "&:hover": { color: "#E0E0E0" },
            }}
          >
            {submitSuccess ? "Close" : "Cancel"}
          </Button>
          {!submitSuccess && (
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={isSubmitting}
              sx={{
                backgroundColor: "#2A2A2A",
                color: "#E0E0E0",
                "&:hover": {
                  backgroundColor: "#3A3A3A",
                },
              }}
            >
              {isSubmitting ? "Saving..." : "Save Prompt"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PromptsPage;
