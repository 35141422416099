import React from "react";

interface State {
  hasError: boolean;
}

class ResizeObserverErrorBoundary extends React.Component<
  React.PropsWithChildren<{}>,
  State
> {
  constructor(props: React.PropsWithChildren<{}>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any): State {
    if (
      error &&
      error.message &&
      error.message.includes("ResizeObserver loop completed")
    ) {
      return { hasError: true };
    }
    return { hasError: false };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Optionally, log the error to an error reporting service
    console.error("ResizeObserver error caught:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}

export default ResizeObserverErrorBoundary;
