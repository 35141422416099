import React from "react";
import { Typography, styled, CardProps } from "@mui/material";
import {
  ThreadsUser,
  ThreadsUserInsights,
} from "../../../../functions/src/types/threadsTypes";

interface ThreadsUserInsightsCardProps extends CardProps {
  threadsUser: ThreadsUser;
  threadsUserInsights?: ThreadsUserInsights;
}

const StyledCard = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const StyledBox = styled("div")({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  textAlign: "center",
  padding: 10,
  margin: 10,
  border: "2px solid #333",
  borderRadius: "30px",
});

const ThreadsUserInsightsCard: React.FC<ThreadsUserInsightsCardProps> = ({
  threadsUser,
  threadsUserInsights,
  ...cardProps
}) => {
  const data = threadsUserInsights?.data;
  if (!data) {
    return null;
  }

  let totalViews = 0;
  let totalLikes = 0;
  let totalReplies = 0;
  let totalReposts = 0;
  let followers_count = 0;
  data.forEach((insight) => {
    if (insight.title === "views") {
      totalViews =
        insight.values && insight.values.length > 0
          ? insight.values[0].value
          : 0;
    } else if (insight.title === "likes") {
      totalLikes = insight.total_value?.value || 0;
    } else if (insight.title === "replies") {
      totalReplies = insight.total_value?.value || 0;
    } else if (insight.title === "reposts") {
      totalReposts = insight.total_value?.value || 0;
    } else if (insight.title === "followers_count") {
      followers_count = insight.total_value?.value || 0;
    }
  });
  return (
    <StyledCard {...cardProps}>
      {/* Stats Display */}
      <StyledBox>
        <Typography variant="h6" sx={{ mb: 1 }}>
          총 조회수: {totalViews}
        </Typography>
        <Typography variant="h6" sx={{ mb: 1 }}>
          총 좋아요: {totalLikes}
        </Typography>
        <Typography variant="h6" sx={{ mb: 1 }}>
          총 답글: {totalReplies}
        </Typography>
        <Typography variant="h6" sx={{ mb: 1 }}>
          총 리포스트: {totalReposts}
        </Typography>
        <Typography variant="h6" sx={{ mb: 1 }}>
          총 팔로워 수: {followers_count}
        </Typography>
      </StyledBox>
    </StyledCard>
  );
};

export default ThreadsUserInsightsCard;
