import React, { useState } from "react";
import { Box } from "@mui/system";
import { Thread } from "../../../../functions/src/types/threadsTypes";
import styled from "styled-components";
import { CircularProgress, Typography } from "@mui/material";
import ThreadPreviewCard from "./ThreadPreviewCard";
import { useThreads } from "../../context/ThreadsContext";
import ThreadDetailModal from "./ThreadDetaiModel";

const StyledThreadsContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "flex-start",
  flexWrap: "wrap",
  flexGrow: 1,
  backgroundColor: "#121212",
});

interface ThreadsContainerProps {
  threads: Thread[];
  loading: boolean;
}

const ThreadsContainer: React.FC<ThreadsContainerProps> = ({
  threads,
  loading,
}) => {
  const { analyzeSingleThread, threadsUserId } = useThreads();
  const [selectedThread, setSelectedThread] = useState<Thread | undefined>(
    undefined
  );
  const [detailOpen, setDetailOpen] = useState(false);

  const handleAnalyzeSingleThread = async (
    threadId: string,
    setIsLoading: (status: boolean) => void
  ) => {
    console.log(
      "[ThreadsPage] handleAnalyzeSingleThread => threadId:",
      threadId
    );
    try {
      setIsLoading(true);
      await analyzeSingleThread(threadId, threadsUserId || "");
      console.log(
        "[ThreadsPage] Single thread analyzed successfully:",
        threadId
      );
    } catch (err) {
      console.error("[ThreadsPage] Failed to analyze single thread:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleThreadClick = (thread: Thread) => {
    console.log("[ThreadsPage] handleThreadClick => threadId:", thread.id);
    setSelectedThread(thread);
    setDetailOpen(true);
  };

  const handleCloseModal = () => {
    console.log("[ThreadsPage] handleCloseModal => detail modal closed");
    setDetailOpen(false);
    setSelectedThread(undefined);
  };

  if (threads.length === 0 && !loading) {
    return (
      <StyledThreadsContainer>
        <Typography>
          작성하신 쓰레드를 찾을 수 없습니다. 쓰레드를 작성해주세요!
        </Typography>
      </StyledThreadsContainer>
    );
  }

  return (
    <>
      <StyledThreadsContainer>
        {loading ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            py={4}
          >
            <CircularProgress size={40} color="primary" />
          </Box>
        ) : (
          [...threads].map((thread: Thread) => {
            return (
              <React.Fragment key={thread.id}>
                <ThreadPreviewCard
                  thread={thread}
                  onAnalyze={handleAnalyzeSingleThread}
                  onClickCard={() => handleThreadClick(thread)}
                  sx={{ cursor: "pointer" }}
                />
              </React.Fragment>
            );
          })
        )}
      </StyledThreadsContainer>
      <ThreadDetailModal
        open={detailOpen}
        onClose={handleCloseModal}
        thread={selectedThread}
      />
    </>
  );
};

export default ThreadsContainer;
