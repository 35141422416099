import {
  Thread,
  ThreadsMetrics,
} from "../../../functions/src/types/threadsTypes";
import { ThreadsSortTypes } from "../pages/ThreadsPage";

/**
 * Calculate comprehensive metrics for an array of Threads
 * by aggregating fields from 'metadata' and the main Thread as well.
 */
export const calculateThreadsMetrics = (threads: Thread[]): ThreadsMetrics => {
  const totalItems = threads.length;

  // ---------- Frequency Maps + Accumulators ----------
  const tagFrequency = new Map<string, number>();
  const keywordFrequency = new Map<string, number>();
  const topicFrequency = new Map<string, number>();
  const categoryFrequency = new Map<string, number>();
  const hookingFrequency = new Map<string, number>();
  const audienceFrequency = new Map<string, number>();
  const viralityFrequency = new Map<string, number>();
  const ctaFrequency = new Map<string, number>();

  let totalViralScore = 0;
  let countViralScore = 0;

  // ---------- Process Each Thread ----------
  threads.forEach((thread) => {
    const meta = thread.metadata;
    if (!meta) {
      return;
    }

    // tags
    meta.tags?.forEach((tag) => {
      tagFrequency.set(tag, (tagFrequency.get(tag) || 0) + 1);
    });

    // keywords (merge into tags for now)
    meta.keywords?.forEach((keyword) => {
      tagFrequency.set(keyword, (tagFrequency.get(keyword) || 0) + 1);
    });

    // topics
    if (meta.topic) {
      topicFrequency.set(meta.topic, (topicFrequency.get(meta.topic) || 0) + 1);
    }

    // category
    if (meta.category) {
      categoryFrequency.set(
        meta.category,
        (categoryFrequency.get(meta.category) || 0) + 1
      );
    }

    // hooking phrases
    meta.hooking_phrases?.forEach((phrase) => {
      hookingFrequency.set(phrase, (hookingFrequency.get(phrase) || 0) + 1);
    });

    // audience
    if (meta.target_audiences) {
      meta.target_audiences?.forEach((audience) => {
        audienceFrequency.set(
          audience,
          (audienceFrequency.get(audience) || 0) + 1
        );
      });
    }

    // virality factors
    meta.expected_virality_factors?.forEach((factor) => {
      viralityFrequency.set(factor, (viralityFrequency.get(factor) || 0) + 1);
    });

    // call to action
    if (meta.call_to_action) {
      const oldCount = ctaFrequency.get(meta.call_to_action) || 0;
      ctaFrequency.set(meta.call_to_action, oldCount + 1);
    }

    // viral_score (string -> number)
    if (meta.viral_score) {
      const vs = parseFloat(meta.viral_score);
      if (!isNaN(vs)) {
        totalViralScore += vs;
        countViralScore++;
      }
    }
  });

  const avgViralScore =
    countViralScore > 0 ? totalViralScore / countViralScore : 0;

  const metrics: ThreadsMetrics = {
    totalNumberOfThreads: totalItems,
    tags: tagFrequency,
    keywords: keywordFrequency,
    topics: topicFrequency,
    categories: categoryFrequency,
    hookingPhrases: hookingFrequency,
    audienceSegments: audienceFrequency,
    callToActions: ctaFrequency,
    viralityFactors: viralityFrequency,
    avgViralScore,
  };

  return metrics;
};

export const sortThreadsByType = (
  threads: Thread[],
  threadsSortType: ThreadsSortTypes
) => {
  console.log("sortThreadsByType", threadsSortType);
  return threads.sort((a, b) => {
    switch (threadsSortType) {
      case "views":
        return (
          (b.insights?.find((insight) => insight.name === "views")?.values[0]
            .value || 0) -
          (a.insights?.find((insight) => insight.name === "views")?.values[0]
            .value || 0)
        );
      case "likes":
        return (
          (b.insights?.find((insight) => insight.name === "likes")?.values[0]
            .value || 0) -
          (a.insights?.find((insight) => insight.name === "likes")?.values[0]
            .value || 0)
        );
      case "replies":
        return (
          (b.insights?.find((insight) => insight.name === "replies")?.values[0]
            .value || 0) -
          (a.insights?.find((insight) => insight.name === "replies")?.values[0]
            .value || 0)
        );
      case "reposts":
        return (
          (b.insights?.find((insight) => insight.name === "reposts")?.values[0]
            .value || 0) -
          (a.insights?.find((insight) => insight.name === "reposts")?.values[0]
            .value || 0)
        );
      case "shares":
        return (
          (b.insights?.find((insight) => insight.name === "shares")?.values[0]
            .value || 0) -
          (a.insights?.find((insight) => insight.name === "shares")?.values[0]
            .value || 0)
        );
      case "date":
        if (!b.timestamp || !a.timestamp) {
          return 0;
        }
        return (
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );
      default:
        return 0;
    }
  });
};

export const PRIMARY_COLOR = "#C0312B";
export const SECONDARY_COLOR = "#AB312B";
