import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
  styled,
} from "@mui/material";
import {
  ThreadsUser,
  ThreadsUserInsights,
} from "../../../../functions/src/types/threadsTypes";
import ThreadsUserInsightsCard from "./ThreadsUserInsightsCard";

const StyledCard = styled(Card)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
});

const StyledInnerCard = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  color: "#E0E0E0",
  padding: "0px 20px",
  "& .MuiTypography-root": {
    color: "#E0E0E0",
  },
});

const StyledAvatar = styled(Avatar)({
  width: 80,
  height: 80,
  marginRight: "16px",
});

interface ThreadsUserCardProps {
  threadsUser: ThreadsUser;
  threadsUserInsights: ThreadsUserInsights | null;
}

const ThreadsUserCard: React.FC<ThreadsUserCardProps> = ({
  threadsUser,
  threadsUserInsights,
}) => {
  return (
    <StyledCard>
      <CardContent>
        <StyledInnerCard>
          <Box
            flexGrow={1}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            p={2}
          >
            <Box flexGrow={1}>
              <StyledAvatar
                src={threadsUser.threads_profile_picture_url}
                alt={threadsUser.username}
              />
            </Box>
            <Box
              flexGrow={5}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Typography variant="h4">{threadsUser.name}</Typography>
              <Typography variant="h5">@{threadsUser.username}</Typography>
              <Typography variant="subtitle2" paddingTop={1}>
                {threadsUser.threads_biography}
              </Typography>
            </Box>
          </Box>
          {threadsUser && threadsUserInsights && (
            <ThreadsUserInsightsCard
              threadsUser={threadsUser}
              threadsUserInsights={threadsUserInsights}
            />
          )}
        </StyledInnerCard>
      </CardContent>
    </StyledCard>
  );
};

export default ThreadsUserCard;
