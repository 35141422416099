import React from "react";
import {
  Box,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ROUTES from "../utils/routes";
import { Link } from "react-router-dom";
import DrawerNavigationBar from "./DrawerNavigationBar";

interface TopBarProps {
  color?: string;
}

const TopBar: React.FC<TopBarProps> = (props) => {
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          m: 1,
          color: "primary",
          width: "100%",
          maxWidth: "100%",
        }}
      >
        {/* Main Links */}
        <Box
          display="flex"
          flexDirection="row"
          textAlign="left"
          justifyContent="flex-start"
          alignItems="center"
          flexGrow={1}
        >
          {/* 커리어해커 알렉스 - First Component (Bigger) */}
          <Link to={ROUTES.main.path} style={{ textDecoration: "none" }}>
            <Typography variant={"h6"} p={1} pl={2} color={"white"}>
              커리어해커 알렉스
            </Typography>
          </Link>

          {/* Wide Top Bar Menu */}
          {isWideScreen && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              ml={3}
            >
              {/* Prompt Generator */}
              <Link
                to={ROUTES.prompt_generator.path}
                style={{ textDecoration: "none" }}
              >
                <Typography variant="subtitle2" p={1} pl={2} color={"white"}>
                  내 프롬프트 만들기
                </Typography>
              </Link>
              {/* Prompt Explorer */}
              <Link
                to={ROUTES.prompt_explorer.path}
                style={{ textDecoration: "none" }}
              >
                <Typography variant="subtitle2" p={1} pl={2} color={"white"}>
                  원하는 프롬프트 찾기
                </Typography>
              </Link>
              {/* ChatGPT Prompts */}
              <Link
                to={ROUTES.prompts.path}
                style={{ textDecoration: "none" }}
              >
                <Typography variant="subtitle2" p={1} pl={2} color={"white"}>
                  ChatGPT 프롬프트
                </Typography>
              </Link>
            </Box>
          )}
        </Box>

        {/* Side Bar Menu */}
        <DrawerNavigationBar />
      </Box>
      <Divider sx={{ width: "100%", opacity: 0.5 }} color={"white"} />
    </>
  );
};

export default TopBar;
