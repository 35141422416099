// TermsAndConditionsPage.tsx
// -----------------------------------
// Summary of Updates:
// 1) Detailed acceptance of Terms, disclaimers, user conduct, and content rules.
// 2) Clear references to future paid features/subscriptions.
// 3) Arbitration clause (California-based), class action waiver, and small claims exception.
// 4) Limitations of liability, disclaimers for AI-generated content.
// 5) Integration with Threads API, OpenAI, Firebase, and relevant disclaimers for 3rd-party services.
// 6) Governing Law set to California, with references to potential international usage.

import React, { useEffect } from "react";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import { Box } from "@mui/material";
import { log } from "../config/firebase";

const terms = `# Terms of Service

_Last Updated: [Month Day, Year]_

Welcome to **Career Hacker Alex**, an AI-powered career assistance platform operated by **Ipsum AI LLC** (“**we**” or “**us**”). These Terms of Service (“**Terms**”) govern your access and use of the website, app, and services (collectively, the “**Service**”). By using the Service, you (“**User**” or “**you**”) agree to these Terms. If you do not agree, please discontinue use.

## 1. Introduction & Acceptance

By creating an account or accessing the Service, you acknowledge you have read and agree to these Terms. If you do not agree, you may not use Career Hacker Alex. If you use the Service on behalf of an organization, you represent that you're authorized to bind that organization.
You must be at least 13 years old to use the Service; those under 18 should have a parent/guardian review and consent to these Terms.

## 2. Service Description

Career Hacker Alex offers:
- **AI Career Assistance:** Integrations with AI (OpenAI) to generate prompts, advice, or analysis.
- **Community Features:** Prompt explorer, user-generated prompts, sharing, commenting.
- **Threads API Integration:** Optionally link your Threads (Meta) account for analytics or content viewing.

We may improve, modify, or remove features at our discretion.

## 3. User Conduct & Content

You agree to:
- Use the Service lawfully and not for malicious or prohibited activities.
- Avoid posting infringing, defamatory, harassing, or obscene content.
- Refrain from introducing malware, attempting unauthorized access, or interfering with functionality.
- Take responsibility for the content you submit. You retain ownership of your posted content, granting us a worldwide license to use it as needed to operate the Service.

## 4. Account Registration & Termination

### Registration

Provide accurate, up-to-date info. You're responsible for safeguarding your login credentials. Notify us of unauthorized use.

### Termination by You

You may delete your account at any time. We will remove or anonymize your data per our Privacy Policy, except as needed for legal obligations.

### Termination by Us

We may suspend or terminate accounts for violations of these Terms, inactivity, or other lawful reasons. If we terminate your account, you lose access to the Service and all associated data (subject to retention policies).

## 5. Third-Party Integrations

- **OpenAI:** AI prompts process user input. We strive to ensure your data is handled securely, but we are not liable for OpenAI's decisions or downtime.
- **Firebase (Google):** Used for authentication, hosting, and analytics. Google's policies may apply.
- **Threads API (Meta):** If linked, your Threads data is subject to Meta's rules.

We are not responsible for third-party platforms or content. Use them at your own risk.

## 6. Subscriptions & Payments

Currently free, Career Hacker Alex may introduce paid subscriptions or features in the future. If so:
- We will clearly explain fees, billing cycles, and cancellation terms.
- Payment processing may occur via third-party (e.g., Stripe, App Stores).
- Unless required by law, fees may be non-refundable. Price or service changes will be communicated with the option to discontinue use before renewal.

## 7. Disclaimers & Limitation of Liability

**“AS IS” Service:** The Service is provided without warranties. We disclaim implied warranties of merchantability and fitness for a particular purpose.

**AI Content:** Advice or suggestions generated by AI are not guaranteed accurate. Users must exercise their own judgment and, if needed, seek professional counsel.

**Limitation:** Ipsum AI LLC is not liable for indirect, incidental, or consequential damages. Our maximum liability is limited to the greater of \$50 USD or amounts you've paid us in the last 12 months.

Some jurisdictions may disallow certain limitations; those limitations shall apply to the fullest extent permitted by law.

## 8. Dispute Resolution & Arbitration

You agree to resolve disputes via **binding arbitration** in California under AAA rules, unless you opt out within 30 days of first agreeing to these Terms. You waive class action rights. Either party may bring claims in small-claims court where applicable or seek injunctive relief for IP infringement.

## 9. Governing Law

These Terms are governed by California law, without conflict of laws provisions. Any litigation (if not arbitrated) shall be in the state or federal courts of California.

## 10. Changes to Terms

We may update these Terms periodically. We will post updates on our website or notify you. Continued use after changes means acceptance of updated Terms. If you disagree, discontinue use.

## 11. Contact Information

**Ipsum AI LLC**  
Email: [careerhackeralex@gmail.com](mailto:careerhackeralex@gmail.com)

`;

const TermsAndConditionsPage: React.FC = () => {
  useEffect(() => {
    log("terms_and_conditions.page_view");
  }, []);

  return (
    <Box m={5}>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
        {terms}
      </ReactMarkdown>
    </Box>
  );
};

export default TermsAndConditionsPage;
