// ThreadsContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import {
  exchangeCodeForTokenFunctions,
  generateThreadsReportFunctions,
  getThreadsFunctions,
  getThreadsUserFunctions,
  loadThreadsCredentialsFunctions,
  fetchThreadsReportFunctions,
  analyzeSingleThreadFunctions,
  getThreadsUserInsightsFunctions,
} from "../api/threadsApi";
import { calculateThreadsMetrics } from "../utils/threadsUtils";

// Threads-related types
import {
  Thread,
  ThreadsReport,
  ThreadsUser,
  ThreadsUserInsights,
  ThreadsMetrics,
} from "../../../functions/src/types/threadsTypes";
import { GetThreadsUserResponse } from "../../../functions/src/types/threadsApiTypes";

interface ThreadsContextData {
  reauthNeeded: boolean;
  accessToken: string;
  threadsUser: ThreadsUser | null;
  threadsUserId: string;
  threadsUserInsights: ThreadsUserInsights | null;
  threads: Thread[];
  threadsReport: ThreadsReport | null;
  threadsMetrics: ThreadsMetrics | null;
  isAuthorized: boolean;
  loadingAuthAndCredentials: boolean;
  loadingThreads: boolean;
  loadingThreadsUser: boolean;
  loadingThreadsUserInsights: boolean;
  loadingForSingleThreadAnalysis: boolean;
  loadingForThreadsReport: boolean;
  error: string | null;
  manualAuthorize: () => void;
  authorize: () => void;
  fetchThreads: (
    forceRefresh?: boolean,
    threadsUserId?: string
  ) => Promise<void>;
  fetchThreadsUser: (
    forceRefresh?: boolean,
    threadsUserId?: string
  ) => Promise<void>;
  fetchThreadsUserInsights: (
    forceRefresh?: boolean,
    threadsUserId?: string
  ) => Promise<void>;
  fetchThreadsReport: (threadsUserId?: string) => Promise<void>;
  generateThreadsReport: (threadsUserId?: string) => Promise<void>;
  analyzeSingleThread: (
    threadId: string,
    threadsUserId?: string
  ) => Promise<void>;
  clearThreadsData: () => void;
  handleAuthorizationCode: (code: string) => Promise<void>;
}

const ThreadsContext = createContext<ThreadsContextData>({
  reauthNeeded: false,
  accessToken: "",
  threadsUser: null,
  threadsUserId: "",
  threadsUserInsights: null,
  threads: [],
  threadsReport: null,
  threadsMetrics: null,
  isAuthorized: false,
  loadingAuthAndCredentials: false,
  loadingThreads: false,
  loadingThreadsUser: false,
  loadingThreadsUserInsights: false,
  loadingForSingleThreadAnalysis: false,
  loadingForThreadsReport: false,
  error: null,
  manualAuthorize: () => {},
  authorize: () => {},
  fetchThreads: async () => {},
  fetchThreadsUser: async () => {},
  fetchThreadsUserInsights: async () => {},
  fetchThreadsReport: async () => {},
  generateThreadsReport: async () => {},
  analyzeSingleThread: async () => {},
  clearThreadsData: () => {},
  handleAuthorizationCode: async () => {},
});

export const ThreadsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useAuth();

  // Core states
  const [threadsUser, setThreadsUser] = useState<ThreadsUser | null>(null);
  const [threadsUserInsights, setThreadsUserInsights] =
    useState<ThreadsUserInsights | null>(null);
  const [threadsUserId, setThreadsUserId] = useState<string>("");
  const [accessToken, setAccessToken] = useState<string>("");
  const [threads, setThreads] = useState<Thread[]>([]);
  const [threadsReport, setThreadsReport] = useState<ThreadsReport | null>(
    null
  );
  const [threadsMetrics, setThreadsMetrics] = useState<ThreadsMetrics | null>(
    null
  );

  // Loading and error states
  const [reauthNeeded, setReauthNeeded] = useState(false);
  const [loadingAuthAndCredentials, setLoadingAuthAndCredentials] =
    useState(false);
  const [loadingThreadsUser, setLoadingThreadsUser] = useState(false);
  const [loadingThreadsUserInsights, setLoadingThreadsUserInsights] =
    useState(false);
  const [loadingThreads, setLoadingThreads] = useState(false);
  const [loadingForSingleThreadAnalysis, setLoadingForSingleThreadAnalysis] =
    useState(false);
  const [loadingForThreadsReport, setLoadingForThreadsReport] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Handling reauthorization
  const [pendingAction, setPendingAction] = useState<
    (() => Promise<void>) | null
  >(null);
  const [isAuthorizing, setIsAuthorizing] = useState(false);

  // --------------------------------------------
  // 1) Utilities
  // --------------------------------------------

  function safeToken(): string {
    if (accessToken === "") {
      console.error("[safeToken] No access token available");
      throw new Error("No access token available");
    }
    return accessToken;
  }

  function isTokenExpiredError(err: any): boolean {
    const msg = err?.message?.toLowerCase() || "";
    return msg.includes("session has expired") || msg.includes("token expired");
  }

  function clearThreadsData() {
    setThreadsUserId("");
    setAccessToken("");
    setThreads([]);
    setThreadsReport(null);
    setThreadsMetrics(null);
    setThreadsUser(null);
    setThreadsUserInsights(null);
    setError(null);
    setReauthNeeded(false);
  }

  // --------------------------------------------
  // 2) Load stored credentials if user logs in
  // --------------------------------------------

  useEffect(() => {
    if (user && accessToken === "" && !loadingAuthAndCredentials) {
      loadStoredCredentials();
    } else if (!user) {
      clearThreadsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function loadStoredCredentials() {
    if (!user) return;
    setLoadingAuthAndCredentials(true);
    try {
      const data = await loadThreadsCredentialsFunctions(user.uid);
      if (data?.data) {
        setThreadsUserId(data.data.user_id ?? "");
        setAccessToken(data.data.access_token ?? "");
      }
    } catch (err) {
      console.error("[loadStoredCredentials] error:", err);
      if (isTokenExpiredError(err)) {
        setReauthNeeded(true);
      } else {
        setError("Failed to load stored credentials");
      }
    } finally {
      setLoadingAuthAndCredentials(false);
    }
  }

  // If we had a pending action after reauth, run it once we have a valid token
  useEffect(() => {
    async function executePendingAction() {
      if (pendingAction && accessToken !== "") {
        try {
          await pendingAction();
        } catch (err) {
          console.error("Error executing pending action:", err);
        } finally {
          setPendingAction(null);
        }
      }
    }
    executePendingAction();
  }, [accessToken, pendingAction]);

  // --------------------------------------------
  // 3) Authorization
  // --------------------------------------------

  function authorize() {
    if (isAuthorizing) return;
    setIsAuthorizing(true);
    sessionStorage.setItem("threadsReturnUrl", window.location.href);

    const clientId = process.env.REACT_APP_THREADS_CLIENT_ID;
    const portPart = window.location.port ? `:${window.location.port}` : "";
    const redirectUri = `https://${window.location.hostname}${portPart}/threads`;
    // dynamically require if you want
    const { getThreadsScopes } = require("../api/threadsApi");
    const scopes = getThreadsScopes();

    const authUrl = `https://www.threads.net/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${encodeURIComponent(scopes)}&response_type=code`;

    window.location.href = authUrl;
  }

  function manualAuthorize() {
    setReauthNeeded(false);
    authorize();
  }

  // --------------------------------------------
  // 4) Handle the OAuth code from Threads
  // --------------------------------------------

  async function handleAuthorizationCode(code: string) {
    if (!user || loadingAuthAndCredentials) return;
    setLoadingAuthAndCredentials(true);
    setError(null);

    try {
      clearThreadsData();
      const response = await exchangeCodeForTokenFunctions(code, user.uid);
      const tokenData = response.data;
      if (!tokenData || !tokenData.user_id || !tokenData.access_token) {
        throw new Error("Missing user_id or access_token");
      }

      // Attempt to fetch user data
      let userData: GetThreadsUserResponse | undefined;
      try {
        userData = await getThreadsUserFunctions(
          user.uid,
          false,
          tokenData.user_id
        );
      } catch (innerErr) {
        console.error(
          "[handleAuthorizationCode] error in getThreadsUserFunctions:",
          innerErr
        );
        userData = undefined;
      }

      setThreadsUserId(tokenData.user_id ?? "");
      setAccessToken(tokenData.access_token ?? "");
      if (userData?.data) {
        setThreadsUser(userData.data);
      }

      // Redirect if we have a return URL
      const returnUrl = sessionStorage.getItem("threadsReturnUrl");
      if (returnUrl) {
        sessionStorage.removeItem("threadsReturnUrl");
        window.location.href = returnUrl;
      }
    } catch (err) {
      console.error("[handleAuthorizationCode] error encountered:", err);
      clearThreadsData();
      setError("Failed to authorize with Threads. Please try again.");
    } finally {
      setLoadingAuthAndCredentials(false);
    }
  }

  // --------------------------------------------
  // 5) A Single “fetcher” Helper to Avoid Repetition
  // --------------------------------------------

  /**
   * fetcher:
   * - Accepts a function that does the actual API call (e.g., getThreadsFunctions).
   * - Manages loading states.
   * - Manages token expiry detection and sets reauthNeeded + pendingAction if needed.
   */
  async function fetcher<T>(
    loadingSetter: React.Dispatch<React.SetStateAction<boolean>>,
    fetchFunc: () => Promise<T>,
    afterSuccess?: (result: T) => void
  ) {
    setError(null);
    loadingSetter(true);
    try {
      const result = await fetchFunc();
      afterSuccess?.(result);
    } catch (err) {
      console.error("[fetcher] error:", err);
      if (isTokenExpiredError(err)) {
        setReauthNeeded(true);
        setPendingAction(() => async () => {
          await fetcher(loadingSetter, fetchFunc, afterSuccess);
        });
      } else {
        setError((err as any)?.message || "An error occurred");
      }
    } finally {
      loadingSetter(false);
    }
  }

  // --------------------------------------------
  // 6) Data-Fetching Methods that Use fetcher
  // --------------------------------------------

  async function fetchThreads(forceRefresh = false, threadsUid?: string) {
    // throws if empty
    if (!user) return setError("Not authorized");
    await fetcher(
      setLoadingThreads,
      async () => {
        const data = await getThreadsFunctions(
          user.uid,
          forceRefresh,
          threadsUid
        );
        return data;
      },
      (res) => {
        const safeThreads = res.data?.threads ?? [];
        setThreads(safeThreads);
        setThreadsMetrics(calculateThreadsMetrics(safeThreads));
      }
    );
  }

  async function fetchThreadsUser(forceRefresh = false, threadsUid?: string) {
    if (!user) return setError("Not authorized");
    await fetcher(
      setLoadingThreadsUser,
      async () => {
        return getThreadsUserFunctions(user.uid, forceRefresh, threadsUid);
      },
      (res) => {
        setThreadsUser(res.data ?? null);
      }
    );
  }

  async function fetchThreadsUserInsights(
    forceRefresh = false,
    threadsUid?: string
  ) {
    if (!user) return setError("Not authorized");
    await fetcher(
      setLoadingThreadsUserInsights,
      async () => {
        return getThreadsUserInsightsFunctions(
          user.uid,
          forceRefresh,
          threadsUid
        );
      },
      (res) => {
        setThreadsUserInsights(res.data || null);
      }
    );
  }

  async function fetchThreadsReport(threadsUid?: string) {
    if (!user) return setError("Not authorized");
    if (loadingForThreadsReport) return;
    await fetcher(
      setLoadingForThreadsReport,
      async () => {
        return fetchThreadsReportFunctions(user.uid, threadsUid);
      },
      (res) => {
        // res can be null if no cached analysis
        if (res) {
          setThreadsReport({ analysis: res.data?.analysis || "" });
        } else {
          setThreadsReport(null);
        }
      }
    );
  }

  async function generateThreadsReport(threadsUid?: string) {
    if (!user) return setError("Not authorized");
    await fetcher(
      setLoadingForThreadsReport,
      async () => {
        return generateThreadsReportFunctions(user.uid, threadsUid);
      },
      (res) => {
        setThreadsReport({ analysis: res.data?.analysis || "" });
      }
    );
  }

  async function analyzeSingleThread(threadId: string, threadsUid?: string) {
    if (!user) return setError("Not authorized");
    await fetcher(
      setLoadingForSingleThreadAnalysis,
      async () => {
        return analyzeSingleThreadFunctions(user.uid, threadId, threadsUid);
      },
      (res) => {
        if (!res.data) {
          throw new Error("No updated thread data returned");
        }
        const newThread: Thread = {
          id: res.data.id,
          username: res.data.username,
          text: res.data.text,
          profilePicUrl: res.data.profilePicUrl,
          timestamp: res.data.timestamp ?? new Date().toISOString(),
          insights: res.data.insights,
          metadata: res.data.metadata,
        };

        setThreads((prev) => {
          const idx = prev.findIndex((t) => t.id === newThread.id);
          if (idx !== -1) {
            const updated = [...prev];
            updated[idx] = { ...updated[idx], ...newThread };
            setThreadsMetrics(calculateThreadsMetrics(updated));
            return updated;
          } else {
            const combined = [...prev, newThread];
            setThreadsMetrics(calculateThreadsMetrics(combined));
            return combined;
          }
        });
      }
    );
  }

  // --------------------------------------------
  // 7) Auto-Fetch if We Have a threadsUserId
  // --------------------------------------------
  useEffect(() => {
    if (threadsUserId !== "") {
      fetchThreads(false, threadsUserId);
      fetchThreadsUser(false, threadsUserId);
      fetchThreadsUserInsights(false, threadsUserId);
      fetchThreadsReport(threadsUserId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadsUserId]);

  // --------------------------------------------
  // 8) Return the Context
  // --------------------------------------------
  return (
    <ThreadsContext.Provider
      value={{
        reauthNeeded,
        accessToken,
        threadsUser,
        threadsUserId,
        threads,
        threadsUserInsights,
        threadsReport,
        threadsMetrics,
        isAuthorized: !!accessToken,
        loadingAuthAndCredentials,
        loadingThreads,
        loadingThreadsUser,
        loadingThreadsUserInsights,
        loadingForSingleThreadAnalysis,
        loadingForThreadsReport,
        error,
        manualAuthorize,
        authorize,
        fetchThreads,
        fetchThreadsUser,
        fetchThreadsUserInsights,
        fetchThreadsReport,
        generateThreadsReport,
        analyzeSingleThread,
        clearThreadsData,
        handleAuthorizationCode,
      }}
    >
      {children}
    </ThreadsContext.Provider>
  );
};

export const useThreads = (): ThreadsContextData => {
  const context = useContext(ThreadsContext);
  if (!context) {
    throw new Error("useThreads must be used within a ThreadsProvider");
  }
  return context;
};

export default ThreadsContext;
