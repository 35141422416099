import React from "react";
import { Thread } from "../../../../functions/src/types/threadsTypes";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../utils/threadsUtils";
interface ThreadDetailModalProps {
  open: boolean;
  onClose: () => void;
  thread?: Thread;
}

const ThreadDetailModal: React.FC<ThreadDetailModalProps> = ({
  open,
  onClose,
  thread,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle
        sx={{
          backgroundColor: "#1E1E1E",
          color: "#E0E0E0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {thread ? "@" + thread.username : null}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: "#E0E0E0",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#1E1E1E", color: "#E0E0E0" }}>
        {thread ? (
          <Box>
            {/* Full Text Section */}
            <Box
              sx={{
                p: 2,
                mb: 2,
                backgroundColor: "#2A2A2A",
                borderRadius: "8px",
                "& img": { maxWidth: "100%" },
                "& h1, & h2, & h3, & h4, & h5, & h6": {
                  fontWeight: "bold",
                  marginTop: "1rem",
                  marginBottom: "0.5rem",
                },
                "& p": {
                  marginBottom: "0.75rem",
                },
              }}
            >
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {thread.text || "Thread Content"}
              </ReactMarkdown>
            </Box>
            <Box sx={{ backgroundColor: "#333", height: "1px", my: 2 }} />
            {/* Metadata Section */}
            {thread.metadata && (
              <Box sx={{ mb: 2 }}>
                {thread.metadata.comments && (
                  <Box
                    sx={{
                      p: 2,
                      mb: 3,
                      backgroundColor: "#2A2A2A",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        "& strong": { color: PRIMARY_COLOR, fontWeight: 600 },
                      }}
                    >
                      <strong>{thread.metadata.comments}</strong>
                    </Typography>
                  </Box>
                )}
                <Box ml={0.5}>
                  {thread.metadata.keywords && (
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 1,
                        "& strong": { color: SECONDARY_COLOR },
                      }}
                    >
                      <strong>핵심 키워드:</strong>{" "}
                      {thread.metadata.keywords.join(", ")}
                    </Typography>
                  )}
                  {thread.metadata.tags && (
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 1,
                        "& strong": { color: SECONDARY_COLOR },
                      }}
                    >
                      <strong>태그:</strong> {thread.metadata.tags.join(", ")}
                    </Typography>
                  )}
                  {thread.metadata.category && (
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 1,
                        "& strong": { color: SECONDARY_COLOR },
                      }}
                    >
                      <strong>카테고리:</strong> {thread.metadata.category}
                    </Typography>
                  )}
                  {thread.metadata.hooking_phrases && (
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 1,
                        "& strong": { color: SECONDARY_COLOR },
                      }}
                    >
                      <strong>후킹 문장:</strong>{" "}
                      {thread.metadata.hooking_phrases.join("; ")}
                    </Typography>
                  )}
                  {thread.metadata.target_audiences && (
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 1,
                        "& strong": { color: SECONDARY_COLOR },
                      }}
                    >
                      <strong>대상 독자:</strong>{" "}
                      {thread.metadata.target_audiences.join(", ")}
                    </Typography>
                  )}
                  <Typography
                    variant="body2"
                    sx={{
                      mb: 1,
                      "& strong": { color: SECONDARY_COLOR },
                    }}
                  >
                    <strong>행동 유도:</strong>{" "}
                    {thread.metadata.call_to_action || "None"}
                  </Typography>
                  {thread.metadata.topic && (
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 1,
                        "& strong": { color: SECONDARY_COLOR },
                      }}
                    >
                      <strong>주제:</strong> {thread.metadata.topic}
                    </Typography>
                  )}
                  {thread.metadata.expected_virality_factors && (
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 1,
                        "& strong": { color: SECONDARY_COLOR },
                      }}
                    >
                      <strong>잠재적 바이럴 요인:</strong>{" "}
                      {thread.metadata.expected_virality_factors.join(", ")}
                    </Typography>
                  )}
                  {thread.metadata.viral_score && (
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 1,
                        "& strong": { color: SECONDARY_COLOR },
                      }}
                    >
                      <strong>바이럴 점수:</strong>{" "}
                      {thread.metadata.viral_score}
                    </Typography>
                  )}
                  {thread.metadata.expected_engagement && (
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 1,
                        "& strong": { color: SECONDARY_COLOR },
                      }}
                    >
                      <strong>예상 참여도:</strong> 좋아요:{" "}
                      {thread.metadata.expected_engagement.likes} | 답글:{" "}
                      {thread.metadata.expected_engagement.replies} | 리포스트:{" "}
                      {thread.metadata.expected_engagement.reposts} | 공유:{" "}
                      {thread.metadata.expected_engagement.shares}
                    </Typography>
                  )}
                  {thread.metadata.sentiment_analysis && (
                    <Box sx={{ mb: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 1,
                          "& strong": { color: PRIMARY_COLOR },
                          fontWeight: 600,
                        }}
                      >
                        <strong>감정 요소 분석</strong>
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ ml: 2, "& strong": { color: SECONDARY_COLOR } }}
                      >
                        <strong>양극성:</strong>{" "}
                        {thread.metadata.sentiment_analysis.polarity}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ ml: 2, "& strong": { color: SECONDARY_COLOR } }}
                      >
                        <strong>톤:</strong>{" "}
                        {thread.metadata.sentiment_analysis.tone}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ ml: 2, "& strong": { color: SECONDARY_COLOR } }}
                      >
                        <strong>강도:</strong>{" "}
                        {thread.metadata.sentiment_analysis.intensity}
                      </Typography>
                    </Box>
                  )}
                  {thread.metadata.readability_structure && (
                    <Box sx={{ mb: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 1,
                          "& strong": { color: PRIMARY_COLOR },
                          fontWeight: 600,
                        }}
                      >
                        <strong>가독성 구조</strong>
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ ml: 2, "& strong": { color: SECONDARY_COLOR } }}
                      >
                        <strong>복잡도:</strong>{" "}
                        {thread.metadata.readability_structure.complexity}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ ml: 2, "& strong": { color: SECONDARY_COLOR } }}
                      >
                        <strong>메모:</strong>{" "}
                        {thread.metadata.readability_structure.notes}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          <Typography variant="body1">No selected thread.</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ThreadDetailModal;
