import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import { app } from "../config/firebase";

// Initialize Firebase Functions
const functions = getFunctions(app, "us-west1");

// Connect to emulator in development
if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export const generatePrompt = async (
  data: string,
  setResponseData: React.Dispatch<React.SetStateAction<string>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  setIsLoading(true);

  try {
    const generatePromptsFunc = httpsCallable(functions, "generatePrompts");
    const result = await generatePromptsFunc({ data });

    if (
      result.data &&
      typeof result.data === "object" &&
      "result" in result.data
    ) {
      const response = (result.data as { result: string }).result;
      setResponseData(response);
      console.log("final prompt:", response);
    } else {
      throw new Error("Invalid response format");
    }
  } catch (error) {
    console.error("Error while generating prompt:", error);
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  } finally {
    setIsLoading(false);
  }
};

export const getPrompts = async (
  lastPromptId?: string,
  searchQueries?: string[]
) => {
  try {
    const getPromptsFunc = httpsCallable(functions, "getPrompts");
    const result = await getPromptsFunc({
      lastPromptId: lastPromptId || null,
      search: searchQueries || [],
    });
    if (!result.data) {
      throw new Error("No data received from server");
    }
    return result.data;
  } catch (error) {
    console.error("Error fetching prompts:", error);
    if (error instanceof Error) {
      throw new Error(`Error fetching prompts: ${error.message}`);
    }
    throw new Error("Unknown error occurred while fetching prompts");
  }
};

export const getPrompt = async (promptId: string) => {
  try {
    const getPromptFunc = httpsCallable(functions, "getPrompt");
    const result = await getPromptFunc({ promptId });
    return result.data;
  } catch (error) {
    console.error("Error fetching prompt:", error);
    throw error;
  }
};

export const updatePrompt = async (promptId: string, data: any) => {
  try {
    const updatePromptFunc = httpsCallable(functions, "updatePrompt");
    const result = await updatePromptFunc({ promptId, ...data });
    return result.data;
  } catch (error) {
    console.error("Error updating prompt:", error);
    throw error;
  }
};

export const deletePrompt = async (promptId: string) => {
  try {
    const deletePromptFunc = httpsCallable(functions, "deletePrompt");
    const result = await deletePromptFunc({ promptId });
    return result.data;
  } catch (error) {
    console.error("Error deleting prompt:", error);
    throw error;
  }
};

export const addComment = async (promptId: string, comment: any) => {
  try {
    const addCommentFunc = httpsCallable(functions, "addComment");
    const result = await addCommentFunc({ promptId, ...comment });
    return result.data;
  } catch (error) {
    console.error("Error adding comment:", error);
    throw error;
  }
};

export const getComments = async (promptId: string) => {
  try {
    const getCommentsFunc = httpsCallable(functions, "getComments");
    const result = await getCommentsFunc({ promptId });
    return result.data;
  } catch (error) {
    console.error("Error fetching comments:", error);
    throw error;
  }
};

export const likePrompt = async (promptId: string) => {
  try {
    const likePromptFunc = httpsCallable(functions, "likePrompt");
    const result = await likePromptFunc({ promptId });
    return result.data;
  } catch (error) {
    console.error("Error liking prompt:", error);
    throw error;
  }
};

export const getUserInfo = async (uid: string) => {
  try {
    const getUserInfoFunc = httpsCallable(functions, "getUserInfo");
    const result = await getUserInfoFunc({ uid });
    return result.data;
  } catch (error) {
    console.error("Error fetching user info:", error);
    throw error;
  }
};

export const updateUserInfo = async (userInfo: any) => {
  try {
    const updateUserInfoFunc = httpsCallable(functions, "updateUserInfo");
    const result = await updateUserInfoFunc(userInfo);
    return result.data;
  } catch (error) {
    console.error("Error updating user info:", error);
    throw error;
  }
};

// Functions for working with the "/my_prompts" collection (DEV mode only)
export const getMyPrompts = async (
  lastPromptId?: string,
  searchQueries?: string[]
) => {
  try {
    const getMyPromptsFunc = httpsCallable(functions, "getMyPrompts");
    const result = await getMyPromptsFunc({
      lastPromptId: lastPromptId || null,
      search: searchQueries || [],
    });
    if (!result.data) {
      throw new Error("No data received from server");
    }
    return result.data;
  } catch (error) {
    console.error("Error fetching my prompts:", error);
    if (error instanceof Error) {
      throw new Error(`Error fetching my prompts: ${error.message}`);
    }
    throw new Error("Unknown error occurred while fetching my prompts");
  }
};

export const createMyPrompt = async (promptData: any) => {
  if (process.env.NODE_ENV !== "development") {
    console.warn("createMyPrompt is only available in development mode");
    return null;
  }

  try {
    const createMyPromptFunc = httpsCallable(functions, "createMyPrompt");
    const result = await createMyPromptFunc(promptData);
    return result.data;
  } catch (error) {
    console.error("Error creating my prompt:", error);
    if (error instanceof Error) {
      throw new Error(`Error creating my prompt: ${error.message}`);
    }
    throw new Error("Unknown error occurred while creating my prompt");
  }
};

export const updateMyPrompt = async (promptId: string, data: any) => {
  if (process.env.NODE_ENV !== "development") {
    console.warn("updateMyPrompt is only available in development mode");
    return null;
  }

  try {
    const updateMyPromptFunc = httpsCallable(functions, "updateMyPrompt");
    const result = await updateMyPromptFunc({ promptId, ...data });
    return result.data;
  } catch (error) {
    console.error("Error updating my prompt:", error);
    if (error instanceof Error) {
      throw new Error(`Error updating my prompt: ${error.message}`);
    }
    throw new Error("Unknown error occurred while updating my prompt");
  }
};

export const deleteMyPrompt = async (promptId: string) => {
  if (process.env.NODE_ENV !== "development") {
    console.warn("deleteMyPrompt is only available in development mode");
    return null;
  }

  try {
    const deleteMyPromptFunc = httpsCallable(functions, "deleteMyPrompt");
    const result = await deleteMyPromptFunc({ promptId });
    return result.data;
  } catch (error) {
    console.error("Error deleting my prompt:", error);
    if (error instanceof Error) {
      throw new Error(`Error deleting my prompt: ${error.message}`);
    }
    throw new Error("Unknown error occurred while deleting my prompt");
  }
};
