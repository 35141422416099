import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  styled,
  Box,
  Button,
  Chip,
  CardProps,
  CircularProgress,
  Divider,
} from "@mui/material";
import {
  Thread,
  ThreadMetadata,
} from "../../../../functions/src/types/threadsTypes";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ko"; // Import Korean locale
// import { PRIMARY_COLOR } from "../../utils/threadsUtils";

dayjs.extend(relativeTime);
dayjs.locale("ko");

const EMPTY_METADATA = {
  keywords: [],
  tags: [],
  category: "",
  sub_category: "",
  hooking_phrases: [],
  target_audiences: [],
  call_to_action: "",
  topic: "",
  expected_virality_factors: [],
  viral_score: "0",
  expected_engagement: {
    likes: 0,
    replies: 0,
    reposts: 0,
    shares: 0,
    views: 0,
    quotes: 0,
  },
  sentiment_analysis: {
    polarity: "",
    tone: "",
    intensity: "",
  },
  readability_structure: {
    complexity: "",
    notes: "",
  },
} as ThreadMetadata;

const RED_COLOR = "#C0312B";

// change to korean
function formatExactDateTime(anyTs: unknown) {
  if (!anyTs) return "날짜 없음";
  let parsedDate: dayjs.Dayjs;
  if (
    typeof anyTs === "string" ||
    typeof anyTs === "number" ||
    anyTs instanceof Date
  ) {
    parsedDate = dayjs(anyTs);
  } else {
    return "날짜 없음";
  }
  if (!parsedDate.isValid()) return "날짜 없음";
  return parsedDate.fromNow();
}

const StyledChip = styled(Chip)({
  margin: "4px",
  backgroundColor: "#333333",
  color: "#E0E0E0",
  "& .MuiChip-label": {
    fontSize: "0.75rem",
  },
  "&:hover": {
    backgroundColor: "#444444",
    transform: "scale(1.05)",
  },
  transition: "all 0.2s ease-in-out",
});

const StyledCard = styled(Card)({
  minWidth: 300,
  maxWidth: 250,
  maxHeight: 430,
  margin: "8px",
  borderRadius: "12px",
  backgroundColor: "#1E1E1E",
  color: "#E0E0E0",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.4)",
    backgroundColor: "#252525",
  },
  "& .MuiTypography-root": {
    color: "#E0E0E0",
  },
  "& .MuiTypography-caption": {
    color: "#999999",
  },
  "& .MuiButton-root": {
    color: "#999999",
    "&:hover": {
      color: "#E0E0E0",
      backgroundColor: "rgba(255, 255, 255, 0.05)",
    },
  },
  "& .MuiCardContent-root": {
    padding: "16px",
  },
});

interface ThreadPreviewCardProps extends CardProps {
  thread: Thread;
  onAnalyze: (
    threadId: string,
    setIsLoading: (status: boolean) => void
  ) => Promise<void>;
  onClickCard?: () => void;
}

const ThreadPreviewCard: React.FC<ThreadPreviewCardProps> = ({
  thread,
  onAnalyze,
  onClickCard,
  ...cardProps
}) => {
  const { text, timestamp, insights, metadata } = thread;

  const {
    tags,
    category,
    sub_category,
    topic,
    expected_virality_factors,
    viral_score,
    readability_structure,
  } = metadata ?? EMPTY_METADATA;

  const [loading, setLoading] = useState(false);

  if (!thread || !text) return null;
  const title = text.split("\n")[0];

  return (
    <StyledCard
      {...cardProps}
      onClick={loading ? undefined : onClickCard}
      sx={{
        cursor: loading ? "default" : onClickCard ? "pointer" : "default",
        opacity: loading ? 0.7 : 1,
        position: "relative",
      }}
    >
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: 1,
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}

      <CardContent>
        <Box display="flex" flexDirection="column">
          <Box padding="10px 14px">
            {title && (
              <Typography variant="subtitle1" fontWeight={600}>
                {title}
              </Typography>
            )}

            {timestamp && (
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Typography
                  variant="caption"
                  sx={{
                    color: "#999999",
                  }}
                >
                  {formatExactDateTime(timestamp)}
                </Typography>
              </Box>
            )}
          </Box>

          {/* inner box */}
          {!tags || tags.length === 0 ? (
            <Box
              sx={{ display: "flex", justifyContent: "center", pt: 2, mb: 2 }}
            >
              <Button
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onAnalyze(thread.id, setLoading);
                  // on onAnalyze complete, set loading as false
                }}
                variant="contained"
                disabled={loading}
                sx={{
                  color: "#999999",
                  "&:hover": {
                    color: "#E0E0E0",
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                  },
                }}
              >
                <Typography variant="caption">쓰레드 분석하기</Typography>
              </Button>
            </Box>
          ) : (
            <Box>
              <Box
                padding="3px 14px"
                marginBottom={1}
                sx={{ maxHeight: "150px", overflow: "scroll" }}
              >
                <Box>
                  {topic && (
                    <>
                      <Typography variant="caption">
                        <strong>주제: </strong>
                        {topic}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box>
                  {expected_virality_factors && (
                    <>
                      <Typography variant="caption">
                        <strong>바이럴 요소: </strong>
                        {expected_virality_factors.join(",")}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box>
                  {readability_structure && (
                    <>
                      <Typography variant="caption">
                        <strong>가독성: </strong>
                        {readability_structure.notes}
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" flexWrap="wrap" p={1}>
                {viral_score && (
                  <StyledChip
                    label={`바이럴 스코어: ${viral_score}`}
                    size="small"
                    sx={{
                      backgroundColor: RED_COLOR,
                      color: "#FFFFFF",
                    }}
                  />
                )}

                {category && sub_category && (
                  <StyledChip
                    size="small"
                    label={`카테고리: ${category}/${sub_category}`}
                    sx={{
                      backgroundColor: "#00695C",
                      color: "#FFFFFF",
                    }}
                  />
                )}

                {tags &&
                  tags.length > 0 &&
                  tags.map((tag, idx) => (
                    <StyledChip
                      key={idx}
                      label={`#${tag}`}
                      size="small"
                      sx={{
                        backgroundColor: "#333333",
                        color: "#E0E0E0",
                        transition: "all 0.2s ease-in-out",
                        "&:hover": {
                          backgroundColor: "#444444",
                        },
                      }}
                    />
                  ))}
              </Box>
            </Box>
          )}

          <Divider flexItem variant="fullWidth" />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            alignContent="center"
            mt={1.3}
          >
            <Typography variant="caption" sx={{ fontSize: "10px" }}>
              {insights
                ?.map(
                  (insight) => `${insight.title}: ${insight.values[0].value}`
                )
                .join(" | ")}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default ThreadPreviewCard;
